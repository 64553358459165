import Vue from 'vue'

const actions = {
  loadIntradayUpdates ({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.axios.get('/intraday_updates.json').then((response) => {
        commit('SET_INTRADAY_UPDATES', { intraday_updates: response.data })

        resolve(response)
      }, error => {
        reject(error)
      })
    })
  },
  load_intraday_update_from_cable ({ commit, state }, { intraday_update }) {
    // Current intraday_updates from state
    var intraday_updates = state.intraday_updates

    if (intraday_updates.filter(iu => iu.id === intraday_update.id).length > 0) {
      // Update existing intraday_update
      const index = intraday_updates.findIndex(iu => iu.id === intraday_update.id)
      Vue.set(intraday_updates, index, intraday_update)
    } else {
      // Add intraday_update
      intraday_updates.unshift(intraday_update)
    }
    commit('SET_INTRADAY_UPDATES', { intraday_updates: intraday_updates })
  }
}

export default actions
