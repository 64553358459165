<template>
  <div class="base-video">
    <div class="video-columns" v-if="layout === 'columns'">
      <b-row>
        <b-col md="3" cols="6">
          <span class="image-wrapper" v-if="!has_video">
            <img class="img-fluid" v-bind:src="video_url"/>
            <span class="play-button">
              <i class="fas fa-play"></i>
            </span>
          </span>
        </b-col>
        <b-col md="6" cols="6" class="no-video">
          <div v-if="!loading">
            <h6>{{ published_at }}</h6>
            <h3>{{ title }}</h3>
          </div>

          <p v-if="!loading">{{ description }}</p>
          <a class="d-block d-md-none" type="submit" @click="select_video(video_id)">
            {{ button_text }}
          </a>
        </b-col>
        <b-col md="3" class="d-none d-md-block">
          <base-button type="submit" @click.native="select_video(video_id)">
            {{ button_text }}
          </base-button>
        </b-col>
      </b-row>
    </div>

    <div class="mobile-columns" v-else>
      <div class="preloader" v-if="loading">
        <base-logo-wave-anim/>
      </div>

      <span class="image-wrapper" v-if="!has_video">
        <img class="img-fluid" v-bind:src="video_url"/>
        <span class="play-button">
          <i class="fas fa-play"></i>
        </span>
      </span>

      <iframe
        class="video-player"
        v-if="has_video && !loading"
        width="100%"
        :src="video_url"
        frameborder="0"
        allowfullscreen="true"
      ></iframe>

      <div v-if="!loading">
        <h6>{{ published_at }}</h6>
        <h3>{{ title }}</h3>
      </div>

      <p v-if="!loading">{{ description }}</p>
    </div>
  </div>
</template>

<script>
import BaseButton from './BaseButton.vue'
import BaseLogoWaveAnim from './BaseLogoWaveAnim.vue'

export default {
  props: {
    video_url: String,
    published_at: String,
    title: String,
    description: String,
    has_video: Boolean,
    loading: Boolean,
    layout: String,
    select_video: Function,
    video_id: String,
    button_text: String
    // lesson_href: String
    // lesson_link_text: String
    // tags: Array
  },
  components: {
    BaseButton,
    BaseLogoWaveAnim
  }
}
</script>

<style scoped lang="scss">
.base-video {
  .image-wrapper {
    position: relative;
    top: 0;
    left: 0;

    img {
      position: relative;
      top: 0;
      left: 0;
    }

    .play-button {
      position: absolute;
      width: 48px;
      height: 48px;
      background-color: #00000066;
      z-index: 100;
      border-radius: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      i {
        line-height: 48px;
        text-align: center;
        color: var(--white);
        margin-left: 1px;
        margin-top: 1px;
        width: 100%;
        font-size: 13px;
      }
    }

    &:hover .play-button {
      opacity: .8;
    }
  }

  ::v-deep button {
    text-transform: uppercase;
    min-width: 150px;
    height: 50px;
    top: 50%;
    right: 35px;
    transform: translateY(-50%);
    position: absolute;
  }
}

.video-columns {
  .image-wrapper {
    &:hover .play-button {
      opacity: 1;
    }
  }

  h6 {
    font-size: 14px;
    color: var(--black-white);
    opacity: 0.5;
    font-weight: 400;
  }

  h3 {
    font-size: 18px;
    color: var(--black-white);
    font-weight: 400;
  }

  a {
    color: var(--blueberry);
    text-transform: uppercase;
    font-family: 'Montserrat', arial, sans-serif;
    font-size: 14px;
    -webkit-appearance: none;
  }

  @media (max-width: 575.98px) {
    .col-6:nth-last-child(2) {
      padding-left: 0;
    }

    h3 {
      margin-bottom: 0;
    }
  }

  @media (max-width: 767.98px) {
    p {
      display: none;
    }
  }
}

.video-player {
  width: 100%;
  min-height: 500px;
}

.preloader {
  width: 100%;
  height: 450px;
  background-color: var(--ice-blue-charcoal-grey-three);
  position: relative;
}
</style>
