const actions = {
  open_popup ({ commit }) {
    commit('OPEN_POPUP')
  },
  close_popup ({ commit }) {
    commit('CLOSE_POPUP')
  }
}

export default actions
