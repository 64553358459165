<template>
  <footer>
    <!-- <b-container class="top-footer">
      <b-row>
        <b-col sm="2">
          <a class="logo" href="#"></a>
        </b-col>
        <b-col sm="10">
          <b-row>
            <b-col sm="3">
              <h6>
                Platform
              </h6>
              <ul class="footer-menu">
                <li><a href="#">Members area</a></li>
                <li><a href="#">Services </a></li>
                <li><a href="#">FAQ</a></li>
                <li><a href="#">Contact</a></li>
              </ul>
            </b-col>
            <b-col sm="3">
              <h6>
                Freebies
              </h6>
              <ul class="footer-menu">
                <li><a href="#">Newsletter</a></li>
                <li><a href="#">Elliott Theory</a></li>
                <li><a href="#">Crypto Analysis</a></li>
                <li><a href="#">Charts</a></li>
              </ul>
            </b-col>
            <b-col sm="3">
              <h6>
                Legal
              </h6>
              <ul class="footer-menu">
                <li><a href="#">Terms And Conditions</a></li>
                <li><a href="#">Privacy Policy</a></li>
                <li><a href="#">Splošni pogoji in opozorilo tveganju</a></li>
                <li><a href="#">Zasebnost in Piškotki</a></li>
              </ul>
            </b-col>
            <b-col sm="3">
              <h6>
                Network
              </h6>
              <ul class="footer-menu">
                <li><a href="#">Twitter</a></li>
                <li><a href="#">Facebook</a></li>
                <li><a href="#">Youtube</a></li>
                <li><a href="#">Google+</a></li>
              </ul>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="sub-footer position-relative">
      <div class="social">
        <ul>
          <li><a href="#"><i class="fab fa-twitter"></i></a></li>
          <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
          <li><a href="#"><i class="fab fa-youtube"></i></a></li>
          <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
        </ul>
      </div>
      <div class="copyright">
        <small>&copy; 2020 ew-forecast.com</small>
      </div>
    </b-container> -->
  </footer>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
footer {
  color: var(--charcoal-grey-white);
  min-height: 1px;

  .top-footer {
    margin-bottom: 50px;
    margin-top: 50px;

    .logo {
      background-image: url(../../assets/logo-wave.svg);
      background-size: 53px 11px;
      background-repeat: no-repeat;
      display: block;
      height: 11px;
      margin: 0 auto;
      margin-top: 7px;
      padding-left: 0;
      pointer-events: none;
      text-indent: -999px;
      width: 57px;
    }

    h6 {
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      margin-bottom: 19px;
    }
  }

  .footer-menu {
    list-style: none;
    padding-left: 0;

    li {
      margin-bottom: 12px;
      line-height: 1;
    }

    a {
      @include basic-transition();
      color: var(--charcoal-grey-white);
      font-size: 12px;
      font-weight: 300;
      line-height: 17px;
      text-decoration: none;
    }

    a:hover {
      color: var(--blueberry);
    }
  }

  .social {
    ul {
      font-size: 19px;
      list-style: none;
      padding-left: 0;
    }

    li {
      display: inline-block;
      padding-right: 40px;
    }

    li:last-child {
      padding-right: 0;
    }

    a {
      @include basic-transition();
      color: var(--charcoal-grey-white);
    }

    a:hover {
      color: var(--blueberry);
    }
  }

  @media (max-width: 575px) {
    text-align: center;

    .top-footer {
      h6 {
        margin-top: 30px;
      }
    }

    .copyright {
      margin-bottom: 10px;
    }
  }

  @media (min-width: 576px) {
    .copyright {
      left: 40px;
      position: absolute;
    }

    .social {
      position: absolute;
      right: 40px;
    }
  }
}
</style>
