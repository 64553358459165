import Vue from 'vue'

const actions = {
  load_video_analysis ({ commit }, { market }) {
    return new Promise((resolve, reject) => {
      Vue.axios.get(`/video_analysis/${market}.json`).then((response) => {
        commit('SET_VIDEO_ANALYSIS', { video_analysis: response.data })

        resolve(response)
      }, error => {
        reject(error)
      })
    })
  },
  load_current_video_analysis ({ commit, state }, { current_video_analysis }) {
    return new Promise((resolve, reject) => {
      Vue.axios.get(`/video_analysis/video_player/${current_video_analysis.ref_no}.json`).then((response) => {
        current_video_analysis.video_player = response.data.video_player

        Vue.axios.get(`/video_analysis/video_meta/${current_video_analysis.ref_no}.json`).then((response) => {
          current_video_analysis.video_meta = response.data.video_meta

          commit('SET_CURRENT_VIDEO_ANALYSIS', { current_video_analysis: current_video_analysis })

          resolve(response)
        }, error => {
          reject(error)
        })

        resolve(response)
      }, error => {
        reject(error)
      })
    })
  }
}

export default actions
