import Vue from 'vue'

const actions = {
  loadCurrentUser ({ commit }) {
    commit('SET_CURRENT_SUBSCRIBER', { item: Vue.auth.user() })
  },
  deleteCurrentUserProfilePhoto ({ commit }, { user_id }) {
    return new Promise((resolve, reject) => {
      Vue.axios.delete('/subscribers/' + user_id + '/profile_photo.json')
        .then(response => {
          commit('REMOVE_CURRENT_SUBSCRIBER_PROFILE_PHOTO')
          resolve(response)
        }, error => {
          reject(error)
        })
    })
  },
  updateCurrentUserProfilePhoto ({ commit }, { profile_photo, user_id }) {
    const formData = new FormData()
    formData.append('subscriber[profile_photo]', profile_photo)

    return new Promise((resolve, reject) => {
      Vue.axios.post('/subscribers/' + user_id + '/profile_photo.json', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        commit('SET_CURRENT_SUBSCRIBER_PROFILE_PHOTO', { item: response.data.profile_photo })
        resolve(response)
      }, error => {
        reject(error)
      })
    })
  },
  updateCurrentUser ({ commit }, { current_user }) {
    const data = {
      id: current_user.id,
      subscriber: {
        name: current_user.name,
        surname: current_user.surname,
        time_zone_id: current_user.time_zone_id,
        company: current_user.company,
        address: current_user.address,
        billing_address: current_user.billing_address,
        city: current_user.city,
        billing_city: current_user.billing_city,
        country_id: current_user.country_id,
        billing_country_id: current_user.billing_country_id,
        zip: current_user.zip,
        billing_zip: current_user.billing_zip,
        eu_vat_id: current_user.eu_vat_id,
        profile_completed: true
      }
    }
    return new Promise((resolve, reject) => {
      Vue.axios.put(
        '/subscribers/' + current_user.id,
        data
      ).then(response => {
        current_user.profile_completed = true

        commit('SET_CURRENT_SUBSCRIBER', { item: current_user })
        resolve(response)
      }, error => {
        reject(error)
      })
    })
  },
  updateCurrentUserPassword ({ commit }, { current_user }) {
    const data = {
      id: current_user.id,
      update_password: true,
      subscriber: {
        current_password: current_user.current_password,
        password: current_user.password,
        password_confirmation: current_user.password_confirmation
      }
    }
    return new Promise((resolve, reject) => {
      Vue.axios.put(
        '/subscribers/' + current_user.id,
        data
      ).then(response => {
        current_user.current_password = ''
        current_user.password = ''
        current_user.password_confirmation = ''

        commit('SET_CURRENT_SUBSCRIBER', { item: current_user })
        resolve(response)
      }, error => {
        reject(error)
      })
    })
  },
  deleteAccount ({ commit }, { current_user }) {
    return new Promise((resolve, reject) => {
      Vue.axios.delete(`/subscribers/${current_user.id}.json`).then(response => {
        resolve(response)
      }, error => {
        reject(error)
      })
    })
  },
  updateSettings ({ commit }, { current_user }) {
    const data = {
      id: current_user.id,
      subscriber: {
        email_notifications: JSON.stringify(current_user.email_notifications)
      }
    }

    return new Promise((resolve, reject) => {
      Vue.axios.post(
        '/subscribers/' + current_user.id + '/update_settings',
        data
      ).then(response => {
        resolve(response)
      }, error => {
        reject(error)
      })
    })
  }
}

export default actions
