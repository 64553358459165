import Vue from 'vue'

const actions = {
  load_trading_notes ({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.axios.get('/trading_notes.json').then((response) => {
        commit('SET_TRADING_NOTES', { trading_notes: response.data })

        resolve(response)
      }, error => {
        reject(error)
      })
    })
  },
  load_trading_note_from_cable ({ commit, state }, { trading_note }) {
    // Current trading_notes from state
    var trading_notes = state.trading_notes

    if (trading_notes.filter(iu => iu.id === trading_note.id).length > 0) {
      // Update existing intraday_update
      const index = trading_notes.findIndex(iu => iu.id === trading_note.id)
      Vue.set(trading_notes, index, trading_note)
    } else {
      // Add intraday_update
      trading_notes.unshift(trading_note)
    }
    commit('SET_TRADING_NOTES', { trading_notes: trading_notes })
  }
}

export default actions
