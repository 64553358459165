const mutations = {
  SET_INSTRUMENTS (state, { instruments }) {
    state.instruments = instruments
  },
  SET_FAVOURITES: (state, { favourites }) => {
    state.favourites = favourites || []
  }
}

export default mutations
