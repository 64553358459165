const getters = {
  slack_dynamic_contents: state => {
    return state.dynamic_contents.filter(
      dynamic_content => dynamic_content.widget.includes('slack')
    )
  },
  help_content: state => {
    return state.dynamic_contents.filter(
      dynamic_content => dynamic_content.widget.includes('help')
    )
  }
}

export default getters
