import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
  namespaced: true,
  state: {
    video_analysis: [],
    current_video_analysis: {
      video_player: '',
      video_meta: {
        description: ''
      }
    }
  },
  actions: actions,
  mutations: mutations,
  getters: getters
}
