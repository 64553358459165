import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './en'

Vue.use(VueI18n)

const defaultLocale = process.env.VUE_APP_DEFAULT_LOCALE
const messages = {
  en: en
}

export default new VueI18n({
  locale: defaultLocale,
  messages
})
