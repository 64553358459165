const mutations = {
  SET_CURRENT_SUBSCRIBER: (state, { item }) => {
    state.current_user = item
  },
  REMOVE_CURRENT_SUBSCRIBER_PROFILE_PHOTO: state => {
    state.current_user.profile_photo = null
  },
  SET_CURRENT_SUBSCRIBER_PROFILE_PHOTO: (state, { item }) => {
    state.current_user.profile_photo = item
  }
}

export default mutations
