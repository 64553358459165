<template>
  <div>
    <img
      src="../assets/logo-wave-white.svg"
      :alt="env.image_alt"
      :width="width"
      v-if="white"
    >

    <img
      src="../assets/logo-wave.svg"
      :alt="env.image_alt"
      :width="width"
      v-else
    >
  </div>
</template>

<script>
export default {
  props: {
    white: {
      type: Boolean,
      default: false
    },
    width: {
      type: String
    }
  },
  data () {
    return {
      env: {
        image_alt: process.env.VUE_APP_NAME
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
