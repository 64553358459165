import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
  namespaced: true,
  state: {
    current_user: {
      products: [],
      subscriptions: [],
      open_door: false,
      subscribe_popup_text: ''
    }
  },
  actions: actions,
  mutations: mutations,
  getters: getters
}
