import Vue from 'vue'

const actions = {
  loadSubscriptionPlans ({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.axios.get('/subscription_plans.json').then((response) => {
        commit('SET_SUBSCRIPTION_PLANS', { subscription_plans: response.data })

        resolve(response)
      }, error => {
        reject(error)
      })
    })
  },
  cancelSubscription ({ commit }, { current_user_id, subscription_id, subscription_type }) {
    return new Promise((resolve, reject) => {
      const data = {
        subscription_data: {
          current_user_id: current_user_id,
          subscription_id: subscription_id,
          subscription_type: subscription_type
        }
      }
      Vue.axios.post('/subscription_plans/cancel_subscription.json', data).then(response => {
        resolve(response)
      }, error => {
        reject(error)
      })
    })
  },
  reactivateSubscription ({ commit }, { current_user_id, subscription_id, subscription_type }) {
    return new Promise((resolve, reject) => {
      const data = {
        subscription_data: {
          current_user_id: current_user_id,
          subscription_id: subscription_id,
          subscription_type: subscription_type
        }
      }
      Vue.axios.post('/subscription_plans/reactivate_subscription.json', data).then(response => {
        resolve(response)
      }, error => {
        reject(error)
      })
    })
  },
  openDoorAccessed ({ commit }, { current_user_id }) {
    return new Promise((resolve, reject) => {
      const data = {
        subscription_data: {
          current_user_id: current_user_id,
          open_door_accessed: true
        }
      }
      Vue.axios.post('/subscription_plans/track_open_door.json', data).then(response => {
        resolve(response)
      }, error => {
        reject(error)
      })
    })
  }
}

export default actions
