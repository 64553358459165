const mutations = {
  SET_UPDATES (state, { updates }) {
    state.updates = updates
  },
  SET_SHOW_CHARTS_UPDATES (state, show_charts_updates) {
    state.show_charts_updates = show_charts_updates
  }
}

export default mutations
