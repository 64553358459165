import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
  namespaced: true,
  state: {
    trading_notes: []
  },
  actions: actions,
  mutations: mutations,
  getters: getters
}
