const mutations = {
  SET_VIDEO_ANALYSIS (state, { video_analysis }) {
    state.video_analysis = video_analysis
  },
  SET_CURRENT_VIDEO_ANALYSIS (state, { current_video_analysis }) {
    state.current_video_analysis = current_video_analysis
  }
}

export default mutations
