import actions from './actions'
import mutations from './mutations'
import getters from './getters'

import instruments from './instruments/store.js'
import video_analysis from './video_analysis/store.js'

export default {
  namespaced: true,
  state: {
    markets: []
  },
  modules: {
    instruments: instruments,
    video_analysis: video_analysis
  },
  actions: actions,
  mutations: mutations,
  getters: getters
}
