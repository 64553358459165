<template>
  <div class="root-layout">
    <main-header />
    <router-view :key="$route.path"></router-view>
  </div>
</template>

<script>
import MainHeader from '../../components/MainHeader.vue'

export default {
  name: 'RootLayout',
  components: {
    MainHeader
  }
}
</script>
