<template>
  <div class="auth-layout">
    <router-view />
  </div>
</template>

<script>
import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'

Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA_V3_SITE_KEY,
  loaderOptions: {
    useRecaptchaNet: true
  }
})

export default {
  name: 'AuthLayout'
}
</script>

<style lang="scss">
body.auth {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: var(--white-black);
}
</style>

<style scoped lang="scss">
::v-deep .auth-form {
  width: 100%;
  max-width: 526px;
  margin: auto;
  padding: 61px 73px;
  color: var(--cool-grey);
  font-size: 13px;
  font-weight: 300;

  box-shadow: 2px 2px 13px 0 var(--shadow-gray-shadow-black);
  background-color: var(--white-black);

  @media (max-width: 991.98px) {
    max-width: 500px;
  }

  @media (max-width: 767.98px) {
    box-shadow: none;
    background-color: transparent;
    padding: 61px 32px;
  }

  .form-control {
    margin-bottom: 24px;
  }

  .bottom-text {
    margin-bottom: 0;
    margin-top: 47px;
  }

  .auth-logo {
    margin-bottom: 61px;
  }
}
</style>
