<template>
  <div class="user-notifications">
    <b-dropdown :variant="unreadNotificationsCount > 0 ? 'custom' : 'custom off'" offset="22px" size="sm" right ref="dropdown">

      <template v-slot:button-content v-if="unreadNotificationsCount > 0">
        <div class="notification-counter">{{ unreadNotificationsCount }}</div>
      </template>

      <div class="notification-wrapper">
        <button @click="closeNotificationsDropdown()" class="close d-block d-md-none" type="button"><i class="fas fa-times"></i></button>

        <b-dropdown-header>
          {{ $t('components.user_notifications.notifications') }}

          <div class="notification-counter d-inline-block d-sm-none" v-if="unreadNotificationsCount > 0">
            {{ unreadNotificationsCount }}
          </div>
        </b-dropdown-header>

        <b-dropdown-item :class="notification.marked_as_read ? '' : 'unread'"
                         v-for="notification in notifications"
                         :key="notification.id"
        >
          <b-row @mouseout="markAsRead(notification.id)">
            <b-col cols="8" sm="9" lg="10" v-html="notification.description" v-on:click.stop></b-col>

            <b-col>
              <small>
                <time-ago :refresh="60" :long="true" :datetime="notification.updated_at" />
              </small>
            </b-col>

          </b-row>
        </b-dropdown-item>
      </div>
    </b-dropdown>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'

export default {
  data () {
    return {
      // keep
    }
  },
  methods: {
    closeNotificationsDropdown () {
      this.$refs.dropdown.hide(true)
    },
    markAsRead (notificationId) {
      this.$store.dispatch('notifications/markNotificationAsRead', { notificationId: notificationId })
    }
  },
  channels: {
    NotificationChannel: {
      received (data) {
        this.$store.dispatch('notifications/load_notification_from_cable', { notification: JSON.parse(data) })
      }
    }
  },
  computed: {
    ...mapState({
      notifications: state => state.notifications.notifications
    }),
    ...mapGetters({
      unreadNotificationsCount: 'notifications/unreadNotificationsCount'
    })
  },
  mounted: function () {
    this.$auth.load().then(() => {
      if (this.$auth.check()) {
        this.$store.dispatch('notifications/loadNotifications')

        this.$cable.subscribe({
          channel: 'NotificationChannel'
        })
      }
    })
  }
}
</script>
<style scoped lang="scss">
.user-notifications {
  ::v-deep .btn-custom {
    outline: none;
    padding: 0;
    width: 28px;
    height: 23px;
    background-image: url(../assets/icon-notification-on.svg);
    background-size: 28px 24px;
    cursor: pointer;
    border: 0;

    &.off {
      background-image: url(../assets/icon-notification-off.svg);
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &::after {
      display: none;
    }
  }

  ::v-deep .dropdown-menu {
    border: 0;
    border-radius: 0;
    margin-top: 28px;
    background-color: var(--white-black);
    box-shadow: 0 2px 14px 0 var(--blue-grey-18-black-18);
    padding: 0;

    @media (min-width: 992px) {
      width: 800px;
    }

    @media (max-width: 991.98px) {
      width: 500px;
    }

    @media (min-width: 576px) and (max-width: 767.98px) {
      .col {
        padding-left: 0;
      }
    }

    @media (max-width: 575.98px) {
      right: 0 !important;
      left: 0 !important;
      position: fixed !important;
      transform: none !important;
      margin-top: 0;
      height: 100%;
      max-height: initial;
      overflow: auto;
      width: 100%;

      li:last-child .dropdown-item {
        border-bottom: 1px solid var(--ice-blue);
      }
    }

    @media only screen
      and (min-width: 320px)
      and (max-width: 767.98px)
      and (orientation: landscape) {
        right: 0 !important;
        left: 0 !important;
        position: fixed !important;
        transform: none !important;
        margin-top: 0;
        height: 100%;
        max-height: initial;
        overflow: auto;

        li:last-child .dropdown-item {
          border-bottom: 1px solid var(--ice-blue);
        }
    }

    .notification-wrapper {
      max-height: 357px;
      overflow: scroll;

      @media (max-width: 575.98px) {
        max-height: initial;
      }

      @media only screen
        and (min-width: 320px)
        and (max-width: 767.98px)
        and (orientation: landscape) {
          max-height: initial;
      }
    }

    button.close {
      position: absolute;
      top: 22px;
      right: 25px;
      color: var(--cool-grey-battleship-grey-three);
      font-size: 17px;
    }

    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      top: .3px;
      right: 0;
      margin-right: 10px;
      border: 8px solid var(--white-black);
      border-color: transparent transparent var(--white-black) var(--white-black);
      transform-origin: 0 0;
      transform: rotate(135deg);
      box-shadow: -4px 4px 8px -4px var(--blue-grey-18-black-18);
    }

    .dropdown-header {
      padding: 15px 28px;
      line-height: 1.5;
      font-size: 14px;
      color: var(--cool-grey);

      @media (max-width: 575.98px) {
        line-height: 2;
        font-size: 16px;
        color: var(--charcoal-grey-white);
      }

      @media only screen
        and (min-width: 320px)
        and (max-width: 767.98px)
        and (orientation: landscape) {
          line-height: 2;
          font-size: 16px;
          color: var(--charcoal-grey-white);
      }

      .notification-counter {
        position: initial;
        padding-left: 5px;
        transform: translateY(-1px);
        margin-left: 10px;
      }
    }

    .dropdown-item {
      border-top: 1px solid var(--ice-blue-charcoal-grey-three);
      padding: 13px 28px;
      line-height: 1.5;
      font-family: 'Athelas', arial, sans-serif;
      margin-right: 120px;
      position: relative;
      color: var(--charcoal-grey-white);
      white-space: normal;

      a {
        color: var(--blueberry-cool-grey);
      }

      &:hover {
        background-color: var(--ice-blue-two-windows-blue-50);

        a {
          color: var(--blueberry-cool-grey);
        }
      }

      .col {
        text-align: right;
      }

      p {
        margin-bottom: 0;
      }

      small,
      .v-time-ago__text {
        font-size: 12px;
      }
    }

    .unread .dropdown-item {
      background-color: var(--ice-blue-two-windows-blue-50);

      &:hover {
        background-color: var(--white-black);
      }
    }
  }

  .notification-counter {
    position: absolute;
    background-color: var(--dark-coral);
    width: 15px;
    height: 15px;
    top: -7px;
    right: -7px;
    border-radius: 100%;
    font-size: 10px;
    color: var(--white);
    font-family: 'Heebo', arial, sans-serif;
    line-height: 1.6;
    padding-left: 1px;

    @media (max-width: 767.98px) {
      padding-left: 0;
    }
  }
}
</style>
