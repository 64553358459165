const getters = {
  is_favourite: state => instrument_id => {
    return state.favourites.includes(instrument_id)
  },
  instruments_in_trading_note: state => instrument_id => {
    return state.instruments.filter(
      instrument => instrument.id === instrument_id
    )
  }
}

export default getters
