<template>
  <a
    v-if="aTag"
    :href="href"
    :target="target"
    class="btn btn-primary"
    :class="{'disabled': disabled }"
  >

    <slot>Link</slot>
  </a>

  <button
    v-else
    type="button"
    class="btn btn-primary"
    :class="{'disabled': disabled }"
    :disabled="disabled"
  >

    <slot>Button</slot>
  </button>
</template>

<script>
export default {
  props: {
    aTag: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    href: {
      type: String,
      default: ''
    },
    target: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
  .btn {
    font-family: 'Montserrat', arial, sans-serif;
    background-color: var(--blueberry);
    border-radius: 0;
    border: none;
    font-size: 14px;
    font-weight: 400;
    min-width: 205px;
  }

  .btn-lg {
    height: 56px;
  }

  .btn-sm {
    min-width: 116px;
    height: 46px;
  }

  a, button {
    line-height: 40px;
  }

  .btn-warning {
    background-color: var(--white);
    color: var(--dark-coral);
    border: 1px solid var(--dark-coral);
  }
</style>
