import { app } from '../../main'
import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

instance.interceptors.request.use(config => {
  app.$Progress.start()

  return config
})

instance.interceptors.response.use(response => {
  app.$Progress.finish()

  return response
})

export default instance
