const actions = {
  load_user_theme ({ commit }) {
    const user_theme = JSON.parse(localStorage.getItem('user_theme'))
    commit('SET_USER_THEME', user_theme)
  },
  update_user_theme ({ commit }, user_theme) {
    localStorage.setItem('user_theme', JSON.stringify(user_theme))
    commit('SET_USER_THEME', user_theme)
  }
}

export default actions
