const getters = {
  userPhotoUrl: state => {
    if (state.current_user === null) return ''

    return state.current_user.profile_photo || process.env.VUE_APP_EMPTY_PROFILE_IMAGE_URL
  },
  userHasPhoto: state => {
    if (state.current_user === null) return ''

    return state.current_user.profile_photo || false
  },
  userFullName: state => {
    if (state.current_user === null) return ''

    if (
      state.current_user.name !== '' &&
      state.current_user.surname !== '' &&
      state.current_user.name !== undefined &&
      state.current_user.surname !== undefined &&
      state.current_user.name !== null &&
      state.current_user.surname !== null
    ) {
      return state.current_user.name + ' ' + state.current_user.surname
    } else {
      if (
        state.current_user.email !== '' &&
        state.current_user.email !== undefined &&
        state.current_user.email !== null
      ) {
        return state.current_user.email.split('@')[0]
      } else {
        return ''
      }
    }
  },
  router_name_for_base_logo: state => {
    if (state.current_user.subscriptions.length === 0) {
      return 'default'
    } else {
      return `${state.current_user.subscriptions[0].markets[0].slug}.instruments`
    }
  }
}

export default getters
