import Vue from 'vue'

const actions = {
  loadDisclaimer ({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.axios.get('/application_settings/disclaimer.json').then((response) => {
        commit('SET_DISCLAIMER', { disclaimer: response.data.disclaimer })

        resolve(response)
      }, error => {
        reject(error)
      })
    })
  }
}

export default actions
