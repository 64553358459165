<template>
  <div class="elliot-wave-school">
    <router-view />

    <div class="videos-list">
      <h2>Video Lessons</h2>

      <b-container>
        <b-row v-if="check_for_subscription()">
          <b-col
            md="4"
            class="video"
            v-for="playlist in playlists"
            v-bind:key="playlist.id"
          >
            <router-link
              :to="{
                name: 'elliot-wave-school.detail',
                params: {
                  svp_playlist_id: playlist.svp_playlist_id
                }
              }"
            >
              <base-video
                video_url='/img/default_ews_video_thumbnail.png'
                :title=playlist.title
              >
              </base-video>
            </router-link>
          </b-col>
        </b-row>

        <b-row v-if="!check_for_subscription()">
          <b-col
            md="4"
            class="video"
            v-for="playlist in playlists"
            v-bind:key="playlist.id"
          >
            <a href="#" @click="open_subscribe_popup()">
              <base-video
                video_url='/img/default_ews_video_thumbnail.png'
                :title=playlist.title
              >
              </base-video>
            </a>
          </b-col>
        </b-row>

      </b-container>
    </div>

    <disclaimer />

    <ews-footer />
  </div>
</template>

<script>
import BaseVideo from '../../components/BaseVideo.vue'
import EwsFooter from '../elliot_wave_school/Footer.vue'
import Disclaimer from '../elliot_wave_school/Disclaimer.vue'

import { mapState, mapActions } from 'vuex'

export default {
  data () {
    return { }
  },
  components: {
    BaseVideo,
    EwsFooter,
    Disclaimer
  },
  mounted () {
    this.$store.dispatch('elliot_wave_schools/load_playlists')
  },
  computed: {
    ...mapState({
      playlists: state => state.elliot_wave_schools.playlists,
      current_user: state => state.profile.account.current_user,
      popup_visibility: state => state.subscribe_popup.popup_visibility
    })
  },
  methods: {
    ...mapActions({
      open_popup: 'subscribe_popup/open_popup'
    }),
    check_for_subscription () {
      if (this.current_user.open_door && this.current_user.open_door_accessed) {
        return true
      }

      const product_subscription = this.current_user.products.filter(p => p.product_key === 'ews').length

      if (product_subscription === 0) {
        return false
      }

      return true
    },
    open_subscribe_popup () {
      this.open_popup()
    }
  }
}
</script>

<style scoped lang="scss">
  .elliot-wave-school {
    background-color: var(--white-black);

    .videos-list {
      a {
        &:hover {
          text-decoration: none;
        }
      }
      h2 {
        text-align: center;
        font-size: 26px;
        color: var(--charcoal-grey-white);
        margin: 45px 0 40px;
      }

      .video {
        margin-top: 30px;
      }

      ::v-deep .base-video {
        padding: 0 15px;

        h6 {
          font-size: 12px;
          color: var(--cool-grey);
          line-height: 2;
          margin: 10px 0 0;
        }

        h3 {
          font-size: 16px;
          color: var(--black-white);
          margin-bottom: 27px;
        }

        p {
          display: none;
        }

        @media (min-width: 992px) {
          .image-wrapper {
            .play-button {
              width: 63px;
              height: 63px;

              i {
                line-height: 63px;
                font-size: 18px;
                margin-top: 0;
                margin-left: 2px;
              }
            }
          }
        }

        @media (max-width: 991.98px) {
          .image-wrapper {
            .play-button {
              i {
                margin-left: 2px;
              }
            }
          }
        }
      }
    }
  }
</style>
