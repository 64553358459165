<template>
  <div>
    <base-logo-wave class="base-logo-wave d-flex" :white="user_theme" width="43px"/>

    <div class="main-text d-flex flex-column align-items-start gap-20">
      <h3 class="mb-0">{{ $t("common.new_app_version_available_title") }}</h3>
      <div class="sub-text d-flex flex-column">
        <p class="mb-0" v-html="$t('common.new_app_version_available_info')"></p>
        <p class="mb-0" v-html="$t('common.new_app_version_available_action')"></p>
      </div>
    </div>

    <base-button
      @click.native="$emit('refresh')"
      class="btn-lg"
      :class="user_theme ? 'btn-light-border' : ''"
    >
      {{ $t("common.new_app_version_available_button") }}
    </base-button>

    <p class="mb-0">{{ $t("common.new_app_version_available_tip") }}</p>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BaseButton from './BaseButton.vue'
import BaseLogoWave from './BaseLogoWave.vue'

export default {
  components: {
    BaseButton,
    BaseLogoWave
  },
  computed: {
    ...mapState({
      user_theme: (state) => state.user_theme.user_theme
    })
  }
}
</script>

<style scoped lang="scss">
.base-logo-wave {
  width: 43px;
}

.main-text {
  gap: 20px;
  width: 380px;

  h3 {
    color: var(--dark-grey-white);
    font-family: 'Montserrat', arial, sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 22px;
  }

  @media (max-width: 575.98px) {
    width: auto;
  }
}

.sub-text {
  width: 380px;

  p {
    color: var(--charcoal-grey-white);
    font-family: 'Athelas', arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.1px;
  }

  @media (max-width: 575.98px) {
    width: auto;
  }
}

.refresh-app {
  > p {
    color: var(--cool-grey-white-60);
    font-family: 'Montserrat', arial, sans-serif;
    font-size: 13px;
    font-weight: 300;
    line-height: 21px;

    width: 371px;

    @media (max-width: 575.98px) {
      width: auto;
    }
  }
}

.btn-lg {
  color: var(--white);
  text-align: center;
  font-family: 'Montserrat', arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;

  background-color: var(--blueberry-light-windows-blue);
  width: 380px;

  @media (max-width: 575.98px) {
    width: auto;
  }
}

.btn-light-border {
  border: 2px solid rgba(255, 255, 255, 0.30);
}
</style>
