const mutations = {
  SET_PLAYLISTS (state, { playlists }) {
    state.playlists = playlists
  },
  SET_VIDEOS (state, { videos }) {
    state.videos = videos
  },
  SET_CURRENT_VIDEO (state, { current_video }) {
    state.current_video = current_video
  }
}

export default mutations
