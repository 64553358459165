<template>
  <div class="trading-notes"
       :class="{'open-trading-notes-content': hidden_trading_notes[instrument_id]}"
       v-if="instrument_trading_notes(instrument_id).length > 0">
    <h4>{{ $t('components.instruments.trading_note') }}</h4>

    <div v-if="!demo" class="trading-notes-content">
      <div ref="trading_note_height"
           v-for="(trading_note, index) in trading_notes_by_publish_date"
           v-bind:key="trading_note.id">
        <b-row v-if="index === 0">
          <b-col cols="2">
            <time-ago :refresh="60" :long="false" :datetime="trading_note.publish_date" />
          </b-col>
          <b-col cols="10">
            <p v-html="trading_note.description"></p>
          </b-col>
        </b-row>

        <b-row v-else class="other-trading-notes">
          <b-col cols="2">
            <time-ago :refresh="60" :long="false" :datetime="trading_note.publish_date" />
          </b-col>
          <b-col cols="10">
            <p v-html="trading_note.description"></p>
          </b-col>
        </b-row>
      </div>
    </div>

    <div v-if="demo" class="trading-notes-content" style="filter: blur(3px);">
      <div ref="trading_note_height"
           v-for="(trading_note, index) in demo_notes()"
           v-bind:key="trading_note.id">
        <b-row v-if="index === 0">
          <b-col cols="2">
            <time-ago :refresh="60" :long="false" :datetime="trading_note.publish_date" />
          </b-col>
          <b-col cols="10">
            <p v-html="trading_note.description"></p>
          </b-col>
        </b-row>

        <b-row v-else class="other-trading-notes">
          <b-col cols="2">
            <time-ago :refresh="60" :long="false" :datetime="trading_note.publish_date" />
          </b-col>
          <b-col cols="10">
            <p v-html="trading_note.description"></p>
          </b-col>
        </b-row>
      </div>
    </div>

    <a v-if="instrument_trading_notes(instrument_id).length > 1 && !demo"
       @click="toggle_trading_notes(instrument_id)"
    >
      {{!hidden_trading_notes[instrument_id] ? $t('components.instruments.show_updates') : $t('components.instruments.close_updates')}}
    </a>

    <a v-else-if="demo"
       @click="open_subscribe_popup()"
    >
      {{!hidden_trading_notes[instrument_id] ? $t('components.instruments.show_updates') : $t('components.instruments.close_updates')}}
    </a>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'

export default {
  components: { },
  props: ['instrument_id', 'demo', 'market'],
  data () {
    return {
      hidden_trading_notes: [],
      height_number: 0,
      demo_trading_notes: [
        [{
          id: 1,
          description: 'Proin pellentesque enim non orci dignissim, sit amet ultricies tortor tempus. Maecenas pulvinar leo nisl, in molestie ipsum ullamcorper varius. Vivamus iaculis.',
          publish_date: Date.now()
        }],
        [{
          id: 1,
          description: 'Sed ornare sollicitudin sapien non sollicitudin. Nunc gravida rutrum ex, eu dignissim mauris porta nec.',
          publish_date: Date.now()
        }],
        [{
          id: 1,
          description: 'Sed luctus, lacus a euismod venenatis, purus eros sollicitudin.',
          publish_date: Date.now()
        }],
        [{
          id: 1,
          description: 'Morbi porttitor euismod mi, at gonil ornare lorem ultricies vitae. Nunc scelerisque fringilla auctor. Nulla et ex.',
          publish_date: Date.now()
        }]
      ]
    }
  },
  mounted () {
    this.$cable.subscribe({
      channel: 'TradingNoteChannel'
    })
  },
  computed: {
    ...mapGetters({
      instrument_trading_notes: 'trading_notes/instrument_trading_notes'
    }),
    ...mapState({
      current_user: state => state.profile.account.current_user,
      popup_visibility: state => state.subscribe_popup.popup_visibility
    }),
    trading_notes_by_publish_date () {
      return this.instrument_trading_notes(this.instrument_id).sort((a, b) => new Date(b.publish_date) - new Date(a.publish_date))
    }
  },
  methods: {
    ...mapActions({
      open_popup: 'subscribe_popup/open_popup'
    }),
    toggle_trading_notes (instrument_id) {
      this.$set(this.hidden_trading_notes, instrument_id, !this.hidden_trading_notes[instrument_id])
    },
    open_subscribe_popup () {
      this.open_popup()
    },
    demo_notes () {
      const rand_num = Math.floor(Math.random() * 4)
      return this.demo_trading_notes[rand_num]
    }
  },
  channels: {
    TradingNoteChannel: {
      received (data) {
        this.$store.dispatch('trading_notes/load_trading_note_from_cable', { trading_note: JSON.parse(data) })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.trading-notes {
  display: block;
  border: 1px solid var(--muted-blue-06-muted-blue);
  background-color: var(--pale-grey-two-muted-blue-80);
  padding: 20px 22px 17px;

  .trading-notes-content {
    .col-2 {
      padding-right: 0;
    }

    .other-trading-notes {
      max-height: 0;
      overflow: hidden;
      @include ease-transition(.5s);
    }
  }

  &.open-trading-notes-content {
    .trading-notes-content {
      .other-trading-notes {
        @include ease-transition(1.5s);
        max-height: 100vh !important;
        height: auto;
      }
    }

    a:after {
      content: '\f077';
    }
  }

  h4 {
    font-size: 13px;
    color: var(--muted-blue);
    margin-bottom: 6px;
  }

  p {
    font-size: 11px;
    color: var(--battleship-grey-three-cool-grey);
    line-height: 28px;
    margin-bottom: 0;
  }

  ::v-deep .v-time-ago__text {
    font-size: 11px;
    color: var(--charcoal-grey-battleship-grey-three);
    font-weight: 500;
  }

  a {
    color: var(--muted-blue);
    display: block;
    text-align: center;
    font-size: 11px;
    line-height: 1;
    margin-top: 12px;
    cursor: pointer;

    &:after {
      content: '\f078';
      font-family: 'Font Awesome 5 Pro';
      font-weight: 900;
      font-size: 9px;
      margin-left: 5px;
    }

    &:hover {
      color: var(--black-white);
      text-decoration: none;
    }
  }
}
</style>
