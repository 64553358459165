<template>
  <header>
    <div class="header-col-1">
      <router-link :to="{ name: router_name_for_base_logo }">
        <base-logo-wave width="35" class="d-none d-sm-block" />
      </router-link>

      <div class="d-block d-sm-none" @click="toggle_mobile_menu()">
        <img src="../assets/icon-hamburger.svg" width="22" alt="Menu" />
      </div>
    </div>

    <div class="header-col-2">
      <nav class="d-sm-block" :class="{'d-none': is_mobile_menu_closed}">
        <span class="close d-sm-none" @click="toggle_mobile_menu()"><i class="fas fa-times"></i></span>
        <router-link :to="{ name: router_name_for_base_logo }">
          <base-logo-wave width="46" class="d-sm-none mobile-menu-logo text-center" />
        </router-link>

        <ul>
          <template v-for="market in markets">
            <li
              :key="market.slug"
              @click="toggle_mobile_submenu(market.slug)"
              :class="['router-link-active' , {'active': toggle[market.slug] && !is_mobile_menu_closed}, {'beta': market.beta}]">

              <router-link :to="{ name: market.slug }">
                {{ $t('components.main_header.' + market.slug ) }}
              </router-link>
            </li>

            <mobile-submenu :toggle="toggle" :market=market :key="market.slug + '_submenu'" />
          </template>

          <li>
            <router-link :to="{ name: 'elliot-wave-school'}">
              {{ $t('components.main_header.elliot_wave_school') }}
            </router-link>
          </li>

          <div class="header-col-3 text-center d-block d-sm-none">
            <toggle-button
              v-model="toggle_user_theme"
              :sync="true"
              :width=70
              :height=23
              :color="{checked: '#34393e', unchecked: 'transparent'}"
              :margin=2
              :labels="{checked: $t('components.main_header.light'), unchecked: $t('components.main_header.dark')}"
            />
          </div>
        </ul>
      </nav>

      <div class="breadcrumbs d-block d-sm-none" v-html="breadcrumbs"></div>
    </div>

    <div class="header-col-3 d-none d-sm-block">
      <toggle-button
        v-model="toggle_user_theme"
        :sync="true"
        :width=60
        :height=18
        :color="{checked: '#34393e', unchecked: 'transparent'}"
        :margin=2
        :labels="{checked: $t('components.main_header.light'), unchecked: $t('components.main_header.dark')}"
      />
    </div>

    <div class="header-col-4">
      <user-notifications
        class="user-notifications"
      />
    </div>

    <div class="header-col-5">
      <user-profile-menu />
    </div>
  </header>
</template>

<script>
import BaseLogoWave from './BaseLogoWave.vue'
import UserNotifications from './UserNotifications.vue'
import UserProfileMenu from './UserProfileMenu.vue'
import MobileSubmenu from './MobileSubmenu.vue'
import { ToggleButton } from 'vue-js-toggle-button'
import { mapGetters, mapState } from 'vuex'

export default {
  data () {
    return {
      is_mobile_menu_closed: true,
      toggle: []
    }
  },
  components: {
    BaseLogoWave,
    UserNotifications,
    UserProfileMenu,
    MobileSubmenu,
    ToggleButton
  },
  watch: {
    '$route' (market) {
      this.is_mobile_menu_closed = true
    }
  },
  mounted () {
    this.$store.dispatch('user_theme/load_user_theme')
    this.$store.dispatch('market/loadMarkets')
    this.change_user_theme(this.user_theme)
  },
  methods: {
    toggle_mobile_menu () {
      this.is_mobile_menu_closed = !this.is_mobile_menu_closed
    },
    toggle_mobile_submenu (market) {
      this.$set(this.toggle, market, !this.toggle[market])
    },
    change_user_theme (user_theme) {
      if (user_theme) {
        document.documentElement.className = 'dark-theme'
      } else {
        document.documentElement.className = 'light-theme'
      }
    }
  },
  computed: {
    ...mapGetters({
      router_name_for_base_logo: 'profile/account/router_name_for_base_logo'
    }),
    ...mapState({
      user_theme: state => state.user_theme.user_theme,
      markets: state => state.market.markets
    }),
    breadcrumbs () {
      if (this.$route.meta.title) {
        return this.$route.meta.title.replace(' > ', '<i class="fas fa-chevron-right arrow-icon"></i>')
      }
      return ''
    },
    toggle_user_theme: {
      get () {
        return this.user_theme
      },
      set (user_theme) {
        this.$store.dispatch('user_theme/update_user_theme', user_theme)
        this.change_user_theme(user_theme)
      }
    }

  }
}
</script>

<style scoped lang="scss">

header {
  height: 70px;
  border-top: 1px solid var(--ice-blue-charcoal-grey-three);
  border-bottom: 1px solid var(--ice-blue-charcoal-grey-three);
  background-color: var(--white-black);
  padding: 0 25px 0 30px;
  line-height: 68px;
  font-size: 14px;
  display: flex;

  .header-col-1 {
    min-width: 65px;
  }

  .header-col-2 {
    width: 100%;
  }

  .header-col-3 {
    min-width: 100px;

    ::v-deep.vue-js-switch {
      border: 1px solid var(--cloudy-blue);
      border-radius: 15px;

      .v-switch-label {
        color: var(--cloudy-blue);
        text-transform: uppercase;
        font-weight: 300;
      }

      .v-switch-core {
        .v-switch-button {
          background-color: var(--white-cloudy-blue);
          box-shadow: 0px 1px 5px -3px;
        }
      }
    }

    @-moz-document url-prefix() {
      .v-switch-label {
        line-height: 17px !important;
      }

      @media (-webkit-device-pixel-ratio: 3) {
        .v-switch-label {
          line-height: 15px !important;
        }
      }
    }

    @-moz-document url-prefix() {
      @media (max-width: 575.98px) {
        .v-switch-label {
          line-height: 22px !important;
        }
      }
      @media (-webkit-device-pixel-ratio: 3) and (max-width: 575.98px) {
        .v-switch-label {
          line-height: 19px !important;
        }
      }
    }
  }

  .header-col-4 {
    min-width: 50px;
  }

  .header-col-5 {
    margin-top: -2px;
  }

  @media (max-width: 575.98px) {
    .header-col-1 {
      min-width: 45px;
    }
  }
}

::v-deep nav {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;

    hr {
      margin: 0;
    }
  }

  li {
    display: inline-block;
    text-align: center;

    &.beta {
      position: relative;
      &:after {
        content: '';
        background-image: url(../assets/beta.jpg);
        background-size: 40px 16px;
        background-repeat: no-repeat;
        width: 40px;
        height: 16px;
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 12px;
        margin-right: 2px;
      }
    }

    a {
      color: var(--charcoal-grey-white);
      display:block;
      padding: 0 44px;
      position: relative;

      @media (max-width: 991.98px) {
        padding: 0 18px;
      }
    }

    a:hover,
    a.router-link-active {
      color: var(--muted-blue-dark-sky-blue);
      text-decoration: none;
    }

    &.router-link-active.active {
      border-bottom: 1px solid var(--ice-blue-charcoal-grey-three);

      a {
        color: var(--charcoal-grey-white);

        &:before {
          content: none;
        }
      }
    }

    a:before,
    a:after {
      @include basic-transition;

      position: absolute;
    }

    a:before {
      bottom: 0;
      left: 0;
      display: block;
      height: 3px;
      width: 0%;
      content: '';
      background-color: var(--muted-blue-dark-sky-blue);
    }

    a:hover:before,
    a.router-link-active:before {
      opacity: 1;
      width: 100%;
    }

    a:hover:after,
    a.router-link-active:after {
      max-width: 100%;
    }
  }

  a .nav-icon {
    width: 12px;
    color: var(--charcoal-grey-white);
    margin-right: 10px;
    padding-bottom: 2px;
  }

  a:hover .nav-icon,
  .router-link-active .nav-icon {
    color: var(--muted-blue);
  }

  @media (max-width: 575.98px) {
    position: fixed;
    width: 100%;
    max-width: 100%;
    z-index: 300;
    top: 0;
    overflow-y: scroll;
    height: 100%;
    left: 0;
    background-color: var(--white-black);

    .header-col-3 {
      margin-top: 20px;
    }

    ul {
      margin-top: 30px;

      li:last-of-type {
        a {
          pointer-events: initial;
        }
      }
    }

    .sub-menu {
      margin-top: 0;

      .level-2 a {
        pointer-events: initial;
      }
    }

    .close {
      position: absolute;
      top: 21px;
      right: 25px;
      color: var(--cool-grey);
      font-size: 17px;

      i {
        text-shadow: none;
      }
    }

    .mobile-menu-logo {
      line-height: initial;
      margin-top: 83px;
    }

    li {
      display: block;
      text-align: left;
      padding-left: 65px;
      line-height: 90px;

      a {
        padding: 0;
        font-size: 17px;
        pointer-events: none;

        .nav-icon {
          font-size: 11px;
          position: absolute;
          top: 39px;
          margin-left: 10px;
        }
      }

      a:before {
        height: 2px;
      }

      &.level-2 {
        padding-left: 110px;
      }
    }
  }
}

::v-deep .arrow-icon {
  color: var(--cool-grey);
  margin: 0 5px;
}

.user-notifications {
  display: inline-block;
}
</style>
