import Vue from 'vue'

const actions = {
  load_playlists ({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.axios.get('/elliot_wave_schools.json').then((response) => {
        commit('SET_PLAYLISTS', { playlists: response.data })

        resolve(response)
      }, error => {
        reject(error)
      })
    })
  },
  load_videos ({ commit }, { svp_playlist_id }) {
    return new Promise((resolve, reject) => {
      Vue.axios.get(`/elliot_wave_schools/${svp_playlist_id}.json`).then((response) => {
        commit('SET_VIDEOS', { videos: response.data })

        resolve(response)
      }, error => {
        reject(error)
      })
    })
  },
  load_current_video ({ commit, state }, { current_video }) {
    return new Promise((resolve, reject) => {
      Vue.axios.get(`/elliot_wave_schools/video_player/${current_video.ref_no}.json`).then((response) => {
        current_video.video_player = response.data.video_player

        Vue.axios.get(`/elliot_wave_schools/video_meta/${current_video.ref_no}.json`).then((response) => {
          current_video.video_meta = response.data.video_meta

          commit('SET_CURRENT_VIDEO', { current_video: current_video })

          resolve(response)
        }, error => {
          reject(error)
        })

        resolve(response)
      }, error => {
        reject(error)
      })
    })
  },
  attach_description_to_videos ({ commit, state }) {
    return new Promise((resolve, reject) => {
      state.videos.forEach((video, index) => {
        Vue.axios.get(`/elliot_wave_schools/video_meta/${video.ref_no}.json`).then((response) => {
          state.videos[index].description = response.data.video_meta.short_description

          resolve(response)
        }, error => {
          reject(error)
        })
      })
    })
  }
}

export default actions
