<template>
  <div class="base-popup" @click="close">
    <div class="popup-wrapper">
      <div class="popup-content" v-on:click.stop>
        <div class="close-popup">
          <span @click="close"><i class="fas fa-times"></i></span>
        </div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.base-popup {
  background-color: var(--white-70-black-70);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;

  .popup-wrapper {
    position: relative;
    width: auto;
    pointer-events: none;
  }
}

.popup-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: var(--white-dark-slate-gray);
  background-clip: padding-box;
  outline: 0;
  margin: 50px auto;
  box-shadow: 2px 2px 13px 0 var(--shadow-gray-shadow-black);
  animation: fadeIn ease .5s;
}

.close-popup {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;

  i {
    color: var(--cloudy-blue);
    font-size: 20px;
    margin-top: 0;
    text-shadow: none;
  }
}
</style>
