import Vue from 'vue'

const actions = {
  loadNotifications ({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.axios.get('/notifications.json').then((response) => {
        // Add marked_as_read parameter to reponse array
        var notifications = response.data

        // Get read notifications IDs from local storage
        var readNotificationIds = JSON.parse(localStorage.getItem('read_notifications')) || []

        readNotificationIds.forEach((readNotificationId, index) => {
          // Get ID match between local storage read notifications and API notifications
          const matchedNotifications = notifications.find(n => n.id === readNotificationId)

          if (matchedNotifications === undefined) {
            // Remove ID from local storage if there is no match - Old notification ID
            readNotificationIds = readNotificationIds.filter(id => id !== readNotificationId)
          } else {
            // Mark as read if there is a match
            matchedNotifications.marked_as_read = true
          }
        })

        // Update local storage and state
        localStorage.setItem('read_notifications', JSON.stringify(readNotificationIds))
        commit('SET_NOTIFICATIONS', { notifications: notifications })

        resolve(response)
      }, error => {
        reject(error)
      })
    })
  },
  markNotificationAsRead ({ commit, state }, { notificationId }) {
    // Get read notifications IDs from local storage
    var readNotificationIds = JSON.parse(localStorage.getItem('read_notifications')) || []

    // Add read notification ID to local storage if not already added
    if (!readNotificationIds.includes(notificationId)) {
      readNotificationIds.push(notificationId)
    }

    // Current notifications from state
    var notifications = state.notifications

    readNotificationIds.forEach((readNotificationId, index) => {
      // Mark as read
      notifications.find(n => n.id === readNotificationId).marked_as_read = true
    })

    // Update local storage and state
    localStorage.setItem('read_notifications', JSON.stringify(readNotificationIds))
    commit('SET_NOTIFICATIONS', { notifications: notifications })
  },
  load_notification_from_cable ({ commit, state }, { notification }) {
    // Current notifications from state
    var notifications = state.notifications

    if (notifications.filter(n => n.id === notification.id).length > 0) {
      // Update existing notification
      const index = notifications.findIndex(n => n.id === notification.id)
      Vue.set(notifications, index, notification)
    } else {
      // Add Notification
      notifications.unshift(notification)
    }
    commit('SET_NOTIFICATIONS', { notifications: notifications })
  }
}

export default actions
