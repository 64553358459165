<template>
  <div class="user-profile-menu">
    <b-dropdown variant="custom" size="sm" right>
      <template v-slot:button-content>
        <img class="rounded-circle" :src="userPhotoUrl">
        <span class="user d-none d-lg-inline-block">{{ userFullName }}</span>

        <span class="dropdown-icon-wrapper d-none d-md-inline-block">
          <i class="fas fa-chevron-down dropdown-icon"></i>
        </span>
      </template>

      <b-dropdown-item :to="{ name: 'profile.account'}">{{ $t('profile.your_account') }}</b-dropdown-item>
      <b-dropdown-item :to="{ name: 'profile.payment_and_subscription'}">{{ $t('profile.payment_and_subscription.title') }}</b-dropdown-item>
      <b-dropdown-item :to="{ name: 'profile.settings'}">{{ $t('profile.notifications_settings') }}</b-dropdown-item>
      <b-dropdown-item @click="logout()">{{ $t('profile.logout') }}</b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  data () {
    return {
      // keep
    }
  },
  computed: {
    ...mapState([
      'profile'
    ]),
    ...mapGetters({
      userPhotoUrl: 'profile/account/userPhotoUrl',
      userFullName: 'profile/account/userFullName'
    })
  },
  methods: {
    logout () {
      this.$auth.logout().then(() => {
        this.$notify({
          type: 'success',
          text: this.$t('auth.sign_out.signed_out')
        })
      })
    }
  },
  mounted: function () {
    // keep
  }
}
</script>

<style scoped lang="scss">
.user-profile-menu {
  ::v-deep .btn-custom {
    outline: none;
    padding: 0;
    color: var(--charcoal-grey-white);

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &::after {
      display: none;
    }
  }

  img {
    width: 25px;
    margin: 0 6px;
  }

  .user {
    font-family: 'Montserrat', arial, sans-serif;
    font-size: 12px;
    font-weight: 400;
    text-transform: capitalize;
  }

  .dropdown-icon-wrapper {
    position: relative;
    top: 2px;
    margin-left: 14px;
    color: var(--cloudy-blue);
  }

  ::v-deep ul.dropdown-menu {
    box-shadow: 0 2px 14px 0 var(--blue-grey-18-black-18);
    border: 0;
    border-radius: 0;
    margin-top: 22px;
    padding: 0;
    background-color: var(--white-black);

    li a {
      font-size: 13px;
      line-height: 13px;
      font-weight: 300;
      padding: 16px 18px 16px 30px;
      color: var(--charcoal-grey-white);

      &:hover {
        background-color: var(--ice-blue-two-windows-blue-50);
      }
    }
  }
}
</style>
