import Vue from 'vue'

const actions = {
  load_dynamic_contents ({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.axios.get('/dynamic_contents.json').then((response) => {
        commit('SET_DYNAMIC_CONTENTS', { dynamic_contents: response.data })

        resolve(response)
      }, error => {
        reject(error)
      })
    })
  }
}

export default actions
