const getters = {
  marketIds: state => {
    return state.markets.map(m => m.id)
  },
  instrumentIds: state => {
    return [].concat(...state.markets.map(m => m.instruments.map(i => i.id)))
  },
  marketInstrumentIds: state => market_id => {
    return [].concat(...state.markets.filter(m => m.id === market_id).map(m => m.instruments.map(i => i.id)))
  }
}

export default getters
