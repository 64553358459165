import Vue from 'vue'
import VueRouter from 'vue-router'
import VueBodyClass from 'vue-body-class'
import routes from './routes'
import i18n from '../i18n'

Vue.use(VueRouter)

Vue.router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
})

const vueBodyClass = new VueBodyClass(routes)

Vue.router.beforeEach((to, from, next) => {
  vueBodyClass.guard(to, next)
})

Vue.router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = process.env.VUE_APP_META_TITLE + ' | ' + to.meta.title
  }

  next()
})

Vue.router.afterEach((to, from) => {
  if (to.meta.auth) {
    if (!Vue.auth.user().profile_completed && Vue.router.currentRoute.name !== 'profile.account' && from.name !== 'login') {
      Vue.router.push({ name: 'profile.account' })

      Vue.notify({
        type: 'warn',
        text: i18n.t('profile.account.update_your_account_data')
      })
    }
  }
})

export default Vue.router
