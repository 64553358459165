<template>
    <div class="popup-wrapper" @click="$router.push({ name: `${market}.instruments` })">
      <div class="intraday-updates-wrapper" v-on:click.stop>
        <div class="close-single">
          <span @click="$router.push({ name: `${market}.instruments` })"><i class="fas fa-times"></i></span>
        </div>

        <div class="intraday-update"
             v-for="intraday_update in filtered_intraday_updates_popup"
             v-bind:key="intraday_update.id"
        >
          <b-row>
            <b-col lg="4" class="left-col">
              <h3 v-if="intraday_update.linked_instruments.length > 0">
                <span v-for="(linked_instrument, index) in intraday_update.linked_instruments"
                      v-bind:key="linked_instrument.id"
                      v-html="comma_to_list(linked_instrument.name, intraday_update.linked_instruments.length - 1, index)"
                ></span>
              </h3>
              <img v-for="market in intraday_update.markets"
                  v-bind:key="market.id"
                  class="market-icon"
                  :src="require(`@/assets/icon-${market.slug}.svg`)"
              >

              <h5><time-ago :refresh="30" :long="true" :datetime="intraday_update.publish_date" /></h5>
              <p v-html="intraday_update.content"></p>

            </b-col>
            <b-col class="right-col">
              <base-tabs
                :tabs=intraday_update.charts
              >
              </base-tabs>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
</template>

<script>
import BaseTabs from '../../components/BaseTabs.vue'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      isHidden: [],
      constants: {
        max_intraday_number_of_graphs: 2,
        max_intraday_text_length: 450
      },
      search: ''
    }
  },
  components: {
    BaseTabs
  },
  beforeMount () {
    this.intraday_updates.forEach((intraday, index) => this.$set(this.isHidden, index, false))
  },
  mounted () {
    this.market = this.$route.matched[0].meta.market

    this.$cable.subscribe({
      channel: 'IntradayUpdateChannel'
    })
  },
  computed: {
    ...mapState({
      intraday_updates: state => state.intraday_updates.intraday_updates
    }),
    filtered_intraday_updates_popup () {
      return this.intraday_updates
        .filter(
          filter_linked_instrument => filter_linked_instrument.linked_instruments.some(
            linked_instrument => linked_instrument.slug.includes(this.$route.params.intraday_updates_instrument_slug)
          )
        )
    }
  },
  methods: {
    save_changes () {
      this.$notify({
        text: this.$t('components.intraday_updates.successfully_updated')
      })
    },
    intraday_update_list_toggle (index) {
      this.$set(this.isHidden, index, !this.isHidden[index])
    },
    comma_to_list (text, list_length, index) {
      if (index !== list_length) {
        return text + ', '
      }

      return text
    }
  },
  channels: {
    IntradayUpdateChannel: {
      received (data) {
        this.$store.dispatch('intraday_updates/load_intraday_update_from_cable', { intraday_update: JSON.parse(data) })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.popup-wrapper {
  background-color: var(--white-70-black-70);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 350;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  text-align: left;
  padding: 100px 40px;
  cursor: default;

  .intraday-updates-wrapper {
    max-width: 1120px;
    margin: 0 auto;
    position: relative;

    .close-single {
      position: absolute;
      right: 40px;
      top: 35px;
      color: var(--cool-grey);
      cursor: pointer;
      z-index: 150;
    }

    .intraday-update {
      padding: 60px 80px;
      background-color: var(--pale-grey-two-charcoal-grey-two);
      margin-bottom: 0;
      border: 1px solid var(--pale-grey-battleship-grey-three);
      margin-bottom: 0;
      border-bottom: 0;
      position: relative;
      max-height: 1000vh;
      overflow: visible;
      @include ease-transition;

      &:last-child {
        border-bottom: 1px solid var(--pale-grey-battleship-grey-three);
      }

      .left-col {
        h3 {
          font-size: 20px;
          color: var(--charcoal-grey-white);
          display: inline-block;
          margin-right: 10px;

          span {
            font-weight: 500;
          }
        }

        img {
          margin-top: -6px;
          margin-right: 5px;
        }

        h5,
        ::v-deep .v-time-ago__text {
          color: var(--cool-grey);
          font-size: 12px;
          font-weight: 300;
          line-height: 2;
          margin-bottom: 20px;
          display: block;
        }

        p {
          font-size: 15px;
          font-family: 'Athelas', arial, sans-serif;
          letter-spacing: .09px;
          color: var(--charcoal-grey-white);
          line-height: 1.73;
          margin-bottom: 0;
        }

        a {
          font-size: 14px;
          color: var(--muted-blue);
          font-family: Heebo;
          cursor: pointer;
        }

        @media (min-width: 1200px) {
          margin-right: 45px;
        }
      }

      .right-col {
        margin-top: 0;
        text-align: left;

        ::v-deep .tab-content {
          height: auto;
          overflow-y: unset;

          img {
            width: 100%;
          }
        }
      }

      ::v-deep .nav-tabs {
        margin-bottom: 35px;
        border-bottom: 0;
        line-height: 0;

        .nav-item {
          width: 33.333%;
          margin-bottom: 0;
          text-align: left;

          &:first-child .nav-link {
            border-right: 0;
          }

          &:last-child .nav-link {
            border-left: 0;
          }

          &:last-child {
            display: block;
          }

          img {
            width: 100%;
          }
        }

        .nav-link {
          background-color: var(--white-black);
          border: 0;
          border-radius: 0;
          padding: 0;
          position: relative;
          border: 1px solid var(--ice-blue-battleship-grey-three);

          h4 {
            font-size: 11px;
            margin-bottom: 0;
            color: var(--flat-blue-two-white);
            line-height: 1.3;
            line-height: 1;
            padding: 7px 15px 3px;
            font-weight: bold;
          }

          small,
          .v-time-ago__text {
            display: block;
            font-size: 10px;
            color: var(--flat-blue-two-white);
            text-transform: lowercase;
            line-height: 1.85;
            padding-bottom: 0;
            float: none;
          }

          img {
            position: inherit;
            transform: inherit;
            width: auto;
            padding: 0px;
            margin: 0 28px 0 15px;
          }

          &.active {
            background-color: var(--muted-blue);

            h4 {
              color: var(--white);
            }

            img {
              filter: brightness(0) invert(1);
            }

            small,
            .v-time-ago__text {
              color: var(--white);
              opacity: 1;
            }
          }

          &:after {
            content: '';
            width: 0;
          }
        }
      }

      @media (max-width: 575.98px) {
        padding: 25px
      }

      ::v-deep .tab-content {
        img {
          border: 1px solid var(--pale-grey);
          margin-bottom: 15px;
          width: 100%
        }

        .trading-note {
          border: 1px solid var(--muted-blue-06);
          background-color: var(--pale-grey-two);
          padding: 20px;

          h4 {
            font-size: 13px;
            color: var(--muted-blue);
            margin-bottom: 8px;
          }

          p {
            font-size: 11px;
            color: var(--battleship-grey);

            ::v-deep span {
              margin-right: 30px;
              color: var(--charcoal-grey-white);
            }
          }

          a {
            color: var(--muted-blue);
            display: block;
            text-align: center;
            font-size: 11px;

            &:after {
              content: '\f078';
              font-family: 'Font Awesome 5 Pro';
              font-weight: 900;
              font-size: 9px;
              margin-left: 5px;
            }

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1500px) {
    padding: 50px;

    div.intraday-single .chart.top-view {
      padding-bottom: 50px;
    }
  }

  @media (max-width: 991.98px) {
    padding: 25px;
    background-color: var(--white-70);

    .intraday-updates-wrapper .intraday-update {
      padding: 45px 45px;

      .left-col {
        margin-bottom: 40px;
      }

      .right-col {
        ::v-deep .nav-tabs {
          margin-bottom: 25px;
        }
      }
    }
  }

  @media (max-width: 575.98px) {
    padding: 0;

    .intraday-updates-wrapper {
      .close-single {
        top: 33px;
      }

      .intraday-update {
        padding: 45px 25px;
      }
    }
  }
}
</style>
