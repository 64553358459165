<template>
  <b-container class="disclaimer-wrapper">
    <div class="disclaimer" v-html="disclaimer"></div>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
  mounted () {
    this.$store.dispatch('application_settings/loadDisclaimer')
  },
  computed: {
    ...mapState({
      disclaimer: state => state.application_settings.disclaimer
    })
  }
}
</script>

<style scoped lang="scss">
  .disclaimer-wrapper {
    padding: 0 50px;
    margin-top: 95px;
    margin-bottom: 65px;

    @media (max-width: 991.98px) {
      padding: 0;
    }

    @media (max-width: 575.98px) {
      padding: 0 25px;
    }
  }

  .disclaimer {
    line-height: 1.7;
    padding: 40px;
    border: 1px solid var(--muted-blue-dark-sky-blue);
    color: var(--brownish-grey-cool-grey);
    font-family: Lato;
    font-size: 10px;

    ::v-deep b {
      font-family: 'Montserrat', arial, sans-serif;
      color: var(--muted-blue-dark-sky-blue);
      font-size: 10px;
      font-weight: 400;
    }

    ::v-deep a {
      color: var(--muted-blue-dark-sky-blue);
      font-size: 10px;
      font-weight: 400;
    }

    @media (max-width: 575.98px) {
      padding: 25px;
    }
  }
</style>
