<template>
  <div class="elliot-wave-school-layout">
    <Header />
    <router-view />
  </div>
</template>

<script>
import Header from '../../components/MainHeader.vue'
import { mapState } from 'vuex'

export default {
  name: 'ElliotWaveSchoolLayout',
  components: {
    Header
  },
  computed: {
    ...mapState({
      current_user: state => state.profile.account.current_user
    })
  },
  mounted () {
    // this.check_for_subscription()
  },
  watch: {
    // current_user () {
    //  this.check_for_subscription()
    // }
  },
  methods: {
    check_for_subscription () {
      const product_subscription = this.current_user.products.filter(p => p.product_key === 'ews').length

      if (product_subscription === 0) {
        this.$router.push({ name: 'profile.payment_and_subscription' })

        this.$notify({
          type: 'warn',
          text: this.$t('profile.payment_and_subscription.not_subscribed_to_ews')
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.elliot-wave-school-layout {
  background-color: var(--white-black);
  min-height: 100vh;
}
</style>
