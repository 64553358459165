<template>
  <div>
    <b-tabs>
      <b-tab v-for="tab in tabs" v-bind:key="tab.id" :active="tab.id === active_tab_id">
        <template v-slot:title v-if="tab.bias">
          <div v-b-tooltip="{trigger: tooltip_trigger, disabled: tab.title.length < 7}" :title="tab.title">
            <h4>
              {{ tab.title | truncate(7, ' ') }}
            </h4>

            <img v-b-tooltip="{trigger: image_tooltip_trigger}" :title="tab.bias.description" v-bind:src="biasIconUrl(tab.bias.key, { big: bias_icon_size})" :alt="tab.bias.description">

            <small>
              <time-ago :refresh="60" :long="false" :datetime="tab.created_at" />
            </small>
          </div>
        </template>
        <template v-slot:title v-else>
          <h4>{{ tab.title }}
            <small>
              <time-ago :refresh="60" :long="false" :datetime="tab.created_at" />
            </small>
          </h4>
        </template>

        <img v-if="!demo" class="img-fluid" v-bind:src="tab.chart_url" @click="open_chart_popup()" alt="Popup">
        <img v-if="demo" class="img-fluid" :src="demo_chart()" @click="open_subscribe_popup" alt="Popup">
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  components: { },
  data () {
    return { }
  },
  props: {
    tabs: Array,
    tooltip_trigger: String,
    chart_title: String,
    image_tooltip_trigger: String,
    bias_icon_size: Boolean,
    active_tab_id: Number,
    market: String,
    demo: Boolean
  },
  computed: {
    ...mapState({
      current_user: state => state.profile.account.current_user,
      popup_visibility: state => state.subscribe_popup.popup_visibility
    })
  },
  methods: {
    ...mapActions({
      open_popup: 'subscribe_popup/open_popup'
    }),
    open_chart_popup () {
      this.$emit('is-popup-open')
    },
    demo_chart () {
      const rand_num = Math.floor(Math.random() * 10) + 1
      const demo_image = `/img/demo_chart-${rand_num}.png`
      return demo_image
    },
    open_subscribe_popup () {
      this.open_popup()
    }
  }
}
</script>

<style scoped lang="scss">

::v-deep .nav-tabs {
  margin-bottom: 35px;
  border-bottom: 0;

  .nav-item {
    width: 33.333%;
    margin-bottom: 0;

    &:first-child .nav-link {
      border-right: 0;
    }

    &:last-child .nav-link {
      border-left: 0;
    }
  }

  .nav-link {
    color: var(--muted-blue);
    border: 0;
    border-radius: 0;
    padding: 0;
    position: relative;
    border: 1px solid var(--ice-blue-charcoal-grey-three);

    div {
      padding: 0 15px;
    }

    h4 {
      font-size: 12px;
      margin-bottom: 0;
      color: var(--charcoal-grey-white);
      line-height: 35px;
      display: inline-block;
      text-transform: uppercase;
    }

    img {
      margin-left: 15px;
      display: inline-block;
    }

    small,
    .v-time-ago__text {
      color: var(--cloudy-blue);
      float: right;
      line-height: inherit;
      line-height: 35px;
    }

    &.active {
      h4 {
        color: var(--white);
      }

      img {
        filter: brightness(0) invert(1);
      }

      small,
      .v-time-ago__text {
        color: var(--white);
        opacity: 0.6;
      }

      background-color: var(--muted-blue);
    }
  }
}

.dark-theme {
  .nav-tabs .nav-link img {
    filter: brightness(0) invert(1);
  }
}

@media (min-width: 1200px) and (max-width: 1599.98px) {
  ::v-deep .nav-tabs {
    .nav-link {
      h4 {
        line-height: 1;
        padding-top: 10px;
        padding-bottom: 5px;
      }

      img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;
      }

      small,
      .v-time-ago__text {
        float: none;
        display: block;
        line-height: 10px;
        padding-bottom: 5px;
      }
    }
  }
}

@media (max-width: 767.98px) {
  ::v-deep .nav-tabs {
    .nav-link {
      h4 {
        line-height: 1;
        padding-top: 10px;
        padding-bottom: 5px;
      }

      img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;
      }

      small,
      .v-time-ago__text {
        float: none;
        display: block;
        line-height: 10px;
        padding-bottom: 5px;
      }
    }
  }
}

::v-deep .tab-content {
  img {
    border: 1px solid var(--pale-grey);
    margin-bottom: 15px;
    width: 100%
  }

  .trading-note {
    border: 1px solid var(--muted-blue-06);
    background-color: var(--pale-grey-two);
    padding: 20px;

    h4 {
      font-size: 13px;
      color: var(--muted-blue);
      margin-bottom: 8px;
    }

    p {
      font-size: 11px;
      color: var(--battleship-grey);

      ::v-deep span {
        margin-right: 30px;
        color: var(--charcoal-grey-white);
      }
    }

    a {
      color: var(--muted-blue);
      display: block;
      text-align: center;
      font-size: 11px;

      &:after {
        content: '\f078';
        font-family: 'Font Awesome 5 Pro';
        font-weight: 900;
        font-size: 9px;
        margin-left: 5px;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}
</style>
