<template>
  <div id="app">
    <notifications position="top left" :duration="5000" animation-type="velocity" />

    <component :is="layout" v-if="authReady" />

    <div class="loading-bar" v-if="!authReady">
      <base-logo-wave-anim />
    </div>
    <vue-progress-bar></vue-progress-bar>

    <div class="refresh-app-wrapper" v-if="updateExists">
      <app-update-popup @refresh="refreshApp" class="refresh-app d-inline-flex flex-column align-items-center"></app-update-popup>
    </div>

    <add-to-homescreen
      iconColor="#fff"
      buttonColor="#5751a8"
      title="Wavetraders"
      iconPath="./img/icons/android-chrome-192x192.png"
      :content="$t('common.add_to_home_screen')"
      :expires=30
    />

    <help v-if="authCheck" />

    <base-popup
      class="subscription-base-popup"
      v-show="popup_visibility"
      @close="close_subscribe_popup"
    >
      <base-logo-wave class="my-5"/>
      <h4 class="mt-4 text-center" v-html="current_user.subscribe_popup_text"></h4>
      <div>
        <base-button
          class="btn-sm my-4"
          @click.native="subscribe">
          {{ $t('popup.subscribe_button') }}
        </base-button>
      </div>
      <small class="mt-4 font-weight-light text-center" v-html="current_user.subscribe_popup_sub_text"></small>
    </base-popup>
  </div>
</template>

<script>
// layouts
import RootLayout from './views/layouts/Root'
import AuthLayout from './views/layouts/Auth'
import ElliotWaveSchoolLayout from './views/layouts/ElliotWaveSchool'
import Help from './components/Help.vue'
import BaseLogoWaveAnim from './components/BaseLogoWaveAnim.vue'
import AppUpdatePopup from './components/AppUpdatePopup.vue'
import { mapState, mapActions } from 'vuex'
import BasePopup from './components/BasePopup.vue'
import BaseButton from './components/BaseButton.vue'
import Vue from 'vue'
import BaseLogoWave from './components/BaseLogoWave.vue'

export default {
  data () {
    return {
      refreshing: false,
      registration: null,
      updateExists: false,
      subscribeTimeoutActive: false,
      unrestrictedPaths: [
        'profile.payment_and_subscription',
        'profile.settings',
        'profile.account',
        'elliot-wave-school'
      ]
    }
  },
  components: {
    BaseLogoWave,
    BaseButton,
    BasePopup,
    'default-layout': RootLayout,
    'profile-layout': RootLayout,
    'auth-layout': AuthLayout,
    'elliot-wave-school-layout': ElliotWaveSchoolLayout,
    Help,
    BaseLogoWaveAnim,
    AppUpdatePopup
  },
  computed: {
    layout () {
      return (this.$route.meta.layout || 'default') + '-layout'
    },
    authReady () {
      return this.$auth.ready()
    },
    authCheck () {
      return this.$auth.check()
    },
    currentPathName () {
      return this.$route.name
    },
    ...mapState({
      wisepops: (state) => state.wisepops.wisepops,
      blocked: (state) => state.blocked.blocked.blocked,
      current_user: (state) => state.profile.account.current_user,
      popup_visibility: (state) => state.subscribe_popup.popup_visibility
    })
  },
  mounted () {
    this.$auth.load().then(() => {
      if (this.$auth.check()) {
        this.$store.dispatch('profile/account/loadCurrentUser').then(() => {
          if (this.current_user.blocked) {
            this.$router.push({ name: 'blocked' })
          }
        })

        this.$store.dispatch('wisepops/loadWisepops').then(() => {
          this.wisepops.forEach((wisepop) => {
            this.$wisepops('event', wisepop.custom_event_name)
          })
        })
      }
    })

    this.$store.dispatch('blocked/checkBlocked').then(() => {
      if (this.blocked) {
        this.$router.push({ name: 'blocked' })
      }
    })

    this.$Progress.finish()
  },
  created () {
    this.$Progress.start()

    document.addEventListener('swUpdated', this.showRefreshUI, { once: true })

    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return

        this.refreshing = true
        window.location.reload()
      })
    }
  },
  watch: {
    currentPathName: function (pathName) {
      if (!this.unrestrictedPaths.includes(this.currentPathName) && !this.subscribeTimeoutActive) {
        this.start_subscriptions_timeout()
      }
    }
  },
  methods: {
    ...mapActions({
      open_popup: 'subscribe_popup/open_popup',
      close_popup: 'subscribe_popup/close_popup'
    }),
    check_for_subscription () {
      if (this.current_user.open_door) {
        return true
      }

      return this.current_user.subscriptions.length > 0
    },
    start_subscriptions_timeout () {
      this.subscribeTimeoutActive = true

      setTimeout(() => {
        if (!this.check_for_subscription() && !this.popup_visibility && Vue.auth.user() && !this.unrestrictedPaths.includes(this.currentPathName)) {
          this.open_subscribe_popup()
        }

        this.subscribeTimeoutActive = false
      }, 60000)
    },
    showRefreshUI (e) {
      this.registration = e.detail
      this.updateExists = true
    },
    refreshApp () {
      this.updateExists = false

      if (!this.registration || !this.registration.waiting) {
        return
      }

      this.registration.waiting.postMessage('skipWaiting')
    },
    subscribe () {
      this.close_popup()
      this.$router.push({ name: 'profile.payment_and_subscription' })
    },
    open_subscribe_popup () {
      this.open_popup()
    },
    close_subscribe_popup () {
      this.close_popup()

      if (!this.subscribeTimeoutActive) {
        this.start_subscriptions_timeout()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.loading-bar {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--white-black);
}

.refresh-app-wrapper {
  position: absolute;
  background-color: var(--shadow-black);
  width: 100%;
  height: 100%;
  z-index: 998;
  top: 0;
  left: 0;
}

.refresh-app {
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;

  background-color: var(--white-windows-blue);
  border-color: var(--light-peach);

  width: 480px;
  padding: 60px 50px;
  gap: 60px;

  @media (max-width: 575.98px) {
    width: 100%;
    height: 100%;
  }
}

::v-deep .add-to-homescreen-container {
  .btn-container {
    float: initial;
    margin: 0 30px;
  }
  .add-button {
    font-family: 'Montserrat', arial, sans-serif;
    font-size: 12px;
    font-weight: 400;
    margin-top: 15px;
    padding: 10px;
  }

  .close_btn {
    z-index: 100;
  }

  @media (min-width: 767.98px) {
    max-width: 500px;
    border: 1px solid var(--pale-grey);
  }
}

::v-deep #IOSmodal {
  background-color: rgba(255, 255, 255, 0.7);

  .modal-content {
    margin: 50% auto;
    padding: 30px;
    background-color: var(--pale-grey-two);
    border: solid 1px var(--pale-grey);
    font-size: 16px;
    font-family: Athelas,arial,sans-serif;
    letter-spacing: .09px;
    color: var(--charcoal-grey);
    text-transform: uppercase;

    ul {
      padding: 0;

      li {
        text-align: center;
        padding-bottom: 30px;

        &:last-child {
          padding-bottom: 0;
        }
      }
    }

    .shareIOS, .addIOS {
      display: block;
      margin: 20px auto 0 auto;
      width: 70px;
    }

    .closeModal {
      font-family: 'Montserrat', arial, sans-serif;
      font-size: 12px;
      font-weight: 400;
      padding: 10px;
      background-color: var(--blueberry) !important;
      color: var(--white);
      margin: 0 50px;
      border-radius: 0;
      border: none;
    }
  }
}
::v-deep .popup-wrapper {
  background-color: var(--shadow-gray);
  height: 100%;

  h4 {
    font-size: 16px;
    font-weight: 400;
    color: var(--dark-grey-white);
    margin-bottom: 14px;
    margin-left: -5px;
    margin-top: 30px;
  }

  .popup-content {
    text-align: center;
    padding: 36px 20px 20px 20px;
    max-width: 400px;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
    color: var(--cool-grey);
  }
}

@media (max-width: 575.98px) {
  ::v-deep  .base-popup {
    padding: 0 15px;
  }
}
</style>
