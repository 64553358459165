import Vue from 'vue'

const actions = {
  loadWisepops ({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.axios.get('/wisepops.json').then((response) => {
        commit('SET_WISEPOPS', { wisepops: response.data })

        resolve(response)
      }, error => {
        reject(error)
      })
    })
  }
}

export default actions
