<template>
  <div class="help" v-if="help_content[0]">
    <div class="help-icon">
      <a @click='toggle_popup_visibility'>
        <i class="far fa-question-circle"></i>
      </a>
    </div>

  <base-popup
    v-show="popup_visibility"
    @close="toggle_popup_visibility"
  >
    <h4>{{ help_content[0].title }}</h4>
    <div v-html="help_content[0].content"></div>
    <video class="mt-2" controls="controls" ref="help_video">
      <source :src="help_content[0].video_url" />
    </video>
  </base-popup>
  </div>
</template>

<script>
import BasePopup from './BasePopup.vue'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      popup_visibility: false
    }
  },
  components: {
    BasePopup
  },
  methods: {
    toggle_popup_visibility () {
      this.$refs.help_video.pause()
      this.popup_visibility = !this.popup_visibility
    }
  },
  computed: {
    ...mapGetters({
      help_content: 'dynamic_contents/help_content'
    })
  },
  mounted () {
    this.$store.dispatch('dynamic_contents/load_dynamic_contents')
  }
}
</script>

<style scoped lang="scss">
h4 {
  font-size: 16px;
  font-weight: 400;
  color: var(--dark-grey-white);
  margin-bottom: 14px;
  margin-left: -5px;
}

::v-deep p {
  font-family: 'Athelas', arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.63;
  letter-spacing: 0.1px;
  margin-bottom: 0;
  color: var(--charcoal-grey-white);
}

::v-deep .popup-wrapper {
  height: 100%;

  .popup-content {
    text-align: center;
    padding: 30px;
    max-width: 800px;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
  }
}

.help {
  .help-icon {
    position: fixed;
    bottom: 10px;
    right: 20px;
    z-index: 500;
    font-size: 25px;
    color: var(--blueberry);

    a {
      cursor: pointer;
    }
  }
}

@media (max-width: 575.98px) {
  ::v-deep .base-popup {
    padding: 0 15px;
    .popup-content {
      padding: 20px;
    }
  }
}

</style>
