<template>
  <div class="elliot-wave-school-single">
    <div class="main-lesson">
      <b-container>
        <base-video
          :video_url=current_video.video_player
          :has_video=true
          :published_at=formated_published_at()
          :title=current_video.title
          :description=strip_html(current_video.video_meta.description)
          :loading=loading
        >
        </base-video>
      </b-container>
    </div>
    <div class="lessons">
      <b-container>
        <div class="other-lessons">
          <div
            class="lesson"
            v-for="video in videos"
            v-bind:key="video.clip_key"
          >
            <base-video
              :description="strip_html(video.description) | truncate(250, '...')"
              video_url='/img/default_ews_video_thumbnail.png'
              :published_at=video.date_created
              :title=video.title
              :has_video=false
              layout='columns'
              :select_video=select_video
              :video_id=video.ref_no
              :button_text="$t('components.elliot_wave_school.detail.play_lesson')"
            >
            </base-video>
          </div>
        </div>
      </b-container>
    </div>

    <ews-footer />
  </div>
</template>

<script>
import BaseVideo from '../../components/BaseVideo.vue'
import EwsFooter from '../elliot_wave_school/Footer.vue'

import { mapState, mapActions } from 'vuex'

export default {
  data () {
    return {
      loading: true,
      svp_playlist_id: null
    }
  },
  components: {
    BaseVideo,
    EwsFooter
  },
  methods: {
    ...mapActions({
      open_popup: 'subscribe_popup/open_popup'
    }),
    formated_published_at () {
      return `${this.$t('common.published_at')} ${this.current_video.date_created}`
    },
    select_video (video_id) {
      this.loading = true
      this.$scrollTo('.elliot-wave-school', 500, { container: 'body', offset: -60 })

      const selected_video = this.videos.find(v => v.ref_no === video_id)
      this.$store.dispatch('elliot_wave_schools/load_current_video', { current_video: selected_video }).then((res) => {
        const that = this
        setTimeout(function () {
          that.loading = false
        }, 500)
      })
    },
    check_for_subscription () {
      if (this.current_user.open_door) {
        return true
      }

      const product_subscription = this.current_user.products.filter(p => p.product_key === 'ews').length

      if (product_subscription === 0) {
        return false
      }

      return true
    },
    open_subscribe_popup () {
      this.open_popup()
    }
  },
  mounted () {
    if (!this.check_for_subscription()) {
      this.open_subscribe_popup()
    }

    this.svp_playlist_id = this.$route.params.svp_playlist_id

    this.$store.dispatch('elliot_wave_schools/load_videos', { svp_playlist_id: this.svp_playlist_id }).then((res) => {
      this.$store.dispatch('elliot_wave_schools/attach_description_to_videos')

      this.$store.dispatch('elliot_wave_schools/load_current_video', { current_video: this.videos[0] }).then((res) => {
        const that = this
        setTimeout(function () {
          that.loading = false
        }, 1500)
      })
    })
  },
  computed: {
    ...mapState({
      videos: state => state.elliot_wave_schools.videos,
      current_video: state => state.elliot_wave_schools.current_video,
      current_user: state => state.profile.account.current_user,
      popup_visibility: state => state.subscribe_popup.popup_visibility
    })
  }
}
</script>

<style scoped lang="scss">
  .elliot-wave-school-single {
    font-family: Heebo;

    ::v-deep .base-video p {
      font-family: 'Lato', sans-serif;
      font-size: 12px;
      line-height: 1.5;
      color: var(--cool-grey);
      margin-bottom: 0;
    }

    .main-lesson {
      background-color: var(--charcoal-grey-two);
      color: var(--white);
      padding: 25px 0 45px;
      border-bottom: 1px solid var(--transparent-charcoal-grey-three);

      .container {
        max-width: 830px;
      }

      ::v-deep .base-video {
        img {
          margin-bottom: 30px;
        }

        h6 {
          font-size: 14px;
          opacity: 0.6;
          font-weight: 400;
          margin-top: 20px;
        }

        h3 {
          font-size: 18px;
          font-weight: 400;
        }

        @media (max-width: 991.98px) {
          .container {
            padding-right: 0;
            padding-left: 0;
          }
        }
      }

      @media (max-width: 991.98px) {
        padding-top: 0;

        .container {
          padding-right: 0;
          padding-left: 0;
        }

        ::v-deep .base-video {
          h6, h3, p {
            padding-right: 20px;
            padding-left: 20px;
          }
        }
      }
    }

    .other-lessons {
      margin-top: 30px;
    }

    .lessons {
      margin-top: 30px;

      .container {
        max-width: 1010px;
      }

      .lesson {
        padding-top: 30px;

        &:after {
          content: '';
          display: block;
          border-bottom: solid 1px var(--very-light-pink-battleship-grey);
          width: 100%;
          padding-top: 30px;
        }

        @media (max-width: 991.98px) {
          &:after {
            width: 120%;
          }
        }

        @media (max-width: 767.98px) {
          &:after {
            margin-left: -20px;
          }
        }
      }

      h3 {
        font-size: 16px;
        color: var(--dark-grey-white);
      }

      @media (max-width: 991.98px) {
        .container {
          padding-right: 20px;
          padding-left: 20px;
        }

        .lesson ::v-deep button {
          right: 16px;
        }
      }
    }
  }
</style>
