import Vue from 'vue'

const actions = {
  loadReports ({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.axios.get('/reports.json').then((response) => {
        commit('SET_REPORTS', { reports: response.data })

        resolve(response)
      }, error => {
        reject(error)
      })
    })
  },
  load_report_from_cable ({ commit, state }, { report }) {
    // Current intraday_updates from state
    var reports = state.reports

    if (reports.filter(iu => iu.id === report.id).length > 0) {
      // Update existing intraday_update
      const index = reports.findIndex(iu => iu.id === report.id)
      Vue.set(reports, index, report)
    } else {
      // Add intraday_update
      reports.unshift(report)
    }
    commit('SET_REPORTS', { reports: reports })
  }
}

export default actions
