<template>
  <div class="videos-list" v-if="!market.disable_video_analysis">
    <div class="main-video" v-if="check_for_subscription()">
      <b-container>
        <base-video
          :video_url=current_video_analysis.video_player
          :has_video=true
          :published_at=formated_published_at()
          :title=current_video_analysis.title
          :description=strip_html(current_video_analysis.video_meta.description)
          :loading=loading
        >
        </base-video>
      </b-container>
    </div>

    <!-- <div class="sort-by">
      {{ $t('components.videos_list.sort_by') }}
      <span>Priority</span>
      <div class="d-inline-block dropdown-icon-wrapper">
        <i class="fas fa-chevron-down"></i>
      </div>
    </div> -->

    <div class="videos pt-5">
      <b-container>
        <b-row>
          <b-col
            sm="4"
            v-for="va in video_analysis"
            v-bind:key="va.clip_key"
          >
            <base-video
              video_url='/img/default_video_thumbnail.png'
              :published_at=va.date_created
              :title=va.title
              :has_video=false
              v-if="check_for_subscription()"
              @click.native="select_video(va.ref_no)"
            >
            </base-video>

            <base-video
              video_url='/img/default_video_thumbnail.png'
              :published_at=va.date_created
              :title=va.title
              :has_video=false
              v-if="!check_for_subscription()"
              @click.native="open_subscribe_popup()"
            >

            </base-video>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import BaseVideo from '../../components/BaseVideo.vue'
import { mapState, mapActions } from 'vuex'

export default {
  data () {
    return {
      market: Object,
      loading: true
    }
  },
  components: { BaseVideo },
  computed: {
    ...mapState({
      video_analysis: state => state.market.video_analysis.video_analysis,
      current_video_analysis: state => state.market.video_analysis.current_video_analysis,
      current_user: state => state.profile.account.current_user,
      popup_visibility: state => state.subscribe_popup.popup_visibility,
      markets: state => state.market.markets
    })
  },
  methods: {
    ...mapActions({
      open_popup: 'subscribe_popup/open_popup'
    }),
    formated_published_at () {
      return `${this.$t('common.published_at')} ${this.current_video_analysis.date_created}`
    },
    select_video (video_id) {
      this.loading = true
      this.$scrollTo('.videos-list', 500, { container: '.content', offset: -60 })

      const selected_video_analysis = this.video_analysis.find(va => va.ref_no === video_id)
      this.$store.dispatch('market/video_analysis/load_current_video_analysis', { current_video_analysis: selected_video_analysis }).then((res) => {
        const that = this
        setTimeout(function () {
          that.loading = false
        }, 500)
      })
    },
    check_for_subscription () {
      if (this.current_user.open_door && this.current_user.open_door_accessed) {
        return true
      }

      const market_subscription = this.current_user.subscriptions.filter(
        s => s.markets.some(
          m => m.slug === this.market.slug
        )
      ).length

      return !(this.current_user.subscriptions.length === 0 || market_subscription === 0)
    },
    open_subscribe_popup () {
      this.open_popup()
    }
  },
  mounted () {
    const that = this
    this.$store.dispatch('market/loadMarkets').then(() => {
      that.market = that.markets.find(market => market.slug === that.$route.matched[0].meta.market)
    })

    this.$store.dispatch('market/video_analysis/load_video_analysis', { market: that.$route.matched[0].meta.market }).then((res) => {
      this.$store.dispatch('market/video_analysis/load_current_video_analysis', { current_video_analysis: this.video_analysis[0] }).then((res) => {
        const that = this
        setTimeout(function () {
          that.loading = false
        }, 500)
      })
    })
  }
}
</script>

<style scoped lang="scss">
  .videos-list {
    .sort-by {
      color: var(--cool-grey);
      text-align: right;
      font-size: 11px;
      line-height: 1.85;
      margin: 50px 30px 35px;

      span {
        font-weight: 600;
        margin-right: 5px;
      }

      .dropdown-icon-wrapper {
        position: relative;
        top: 1px;
      }
    }

    ::v-deep .base-video {
      padding: 0 15px;
      cursor: pointer;
    }

    .main-video {
      background-color: var(--charcoal-grey-two);
      color: var(--white);
      padding-bottom: 45px;
      border-bottom: 1px solid var(--transparent-charcoal-grey-three);

      ::v-deep .base-video {
        .image-wrapper {
          .play-button {
            display: none;
          }
        }

        img {
          display: block;
          margin: 0 auto;
        }

        h6 {
          font-size: 12px;
          color: var(--battleship-grey-two);
          line-height: 2;
          float: right;
          font-weight: 300;
        }

        h3 {
          font-size: 18px;
          color: var(--white);
          margin: 25px 0 12px;
          line-height: 1.3;
          font-weight: 300;
        }

        p {
          display: block;
          color: var(--cool-grey);
          font-size: 12px;
          line-height: 1.5;
          margin-bottom: 10px;
          font-family: 'Lato', sans-serif;
          margin-bottom: 20px;
        }

        ul {
          list-style: none;
          padding: 0;
          margin-bottom: 0;
        }

        li {
          display: inline-block;
          border: 1px solid var(--white-opacity03);
          line-height: 2.18;
          font-size: 11px;
          margin: 0 8px 10px 0;
          padding: 0 5px;
          font-weight: 300;

          span {
            font-weight: bold;
          }
        }

        @media (max-width: 1199.98px) {
          padding: 0;

          img {
            margin-top: 0;
          }

          h6,
          h3,
          p,
          ul {
            padding: 0 20px;
          }
        }

        @media (max-width: 575.98px) {
          h6 {
            float: unset;
            position: absolute;
            margin-top: 25px;
          }

          h3 {
            margin: 15px 0 35px;
          }

          p {
            display: none;
          }
        }
      }

      @media (max-width: 1199.98px) {
        .container {
          padding: 0;
          max-width: initial;
        }
      }

      @media (min-width: 1200px) {
        padding: 25px 30px 45px;
      }
    }

    .videos {
      ::v-deep .base-video .mobile-columns {
        div {
          display: flex;
          flex-direction: column;
        }

        h6 {
          font-size: 10px;
          color: var(--cool-grey);
          line-height: 2;
          margin-bottom: 40px;
          order: 2;
        }

        h3 {
          font-size: 12px;
          color: var(--black-white);
          line-height: 1.6;
          margin-top: 5px;
          margin-bottom: 0;
          order: 1
        }

        p {
          display: none;
        }

        ul {
          order: 3;
        }

        li {
          color: var(--flat-blue-two);
          border: 1px solid var(--dirty-blue);
        }

        @media (max-width: 575.98px) {
          padding: 0;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: flex-start;
          justify-content: center;
          margin-bottom: 20px;

          .image-wrapper {
            max-width: 50%;
            display: inline-block;
          }

          div {
            display: flex;
            flex-direction: column;
            flex: 50%;
            padding-left: 15px;
          }

          h3 {
            margin-bottom: 0;
          }
        }

        @media (max-width: 1199.98px) {
          h6 {
            margin-bottom: 22px;
          }
        }

        @media (min-width: 768px) {
          ul {
            display: none;
          }
        }
      }

      @media (max-width: 1198.98px) {
        padding: 0 5px;

        .container {
          max-width: initial;
        }
      }

      @media (min-width: 1200px) {
        padding: 0 30px;
      }
    }

    @media (max-width: 575.98px) {
      .main-video {
        margin-bottom: 10px;
      }

      .sort-by {
        display: none;
      }
    }

    @media (max-width: 1198.98px) {
      .sort-by {
        margin: 20px 20px;
      }

      @media (max-width: 1199.98px) {
        ::v-deep .base-video {
          padding: 0;
        }
      }
    }

    @media (min-width: 1200px) {
      .sort-by {
        margin: 50px 60px 35px;
      }
    }
  }
</style>
