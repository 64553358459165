import Vue from 'vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import BootstrapVue from 'bootstrap-vue'
import VueAxios from 'vue-axios'
import VueProgressBar from 'vue-progressbar'
import Notifications from 'vue-notification'
import ActionCableVue from 'actioncable-vue'
import TimeAgo from './components/vue2-timeago/TimeAgo'
import VueScrollTo from 'vue-scrollto'
import Hotjar from 'vue-hotjar'
import VueTruncate from 'vue-truncate-filter'
import VueAddtohomescreen from '@owliehq/vue-addtohomescreen'

import VueAuth from '@websanova/vue-auth'
import VueAuthDevise from '@websanova/vue-auth/drivers/auth/devise'
import VueAuthHttp from '@websanova/vue-auth/drivers/http/axios.1.x'
import VueAuthRouter from '@websanova/vue-auth/drivers/router/vue-router.2.x'

import velocity from 'velocity-animate'
import VueCurrencyFilter from 'vue-currency-filter'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'

import './styles/main.scss'

import i18n from './i18n'

import Mixins from './mixins'

import axios from './backend/axios'

import router from './router'
import VueGtag from 'vue-gtag'
import store from './store'

import App from './App.vue'

import './registerServiceWorker'

Bugsnag.start({
  releaseStage: process.env.VUE_APP_ENV,
  enabledReleaseStages: ['production', 'test'],
  apiKey: process.env.VUE_APP_BUGSNAG,
  plugins: [new BugsnagPluginVue()]
})

const bugsnagVue = Bugsnag.getPlugin('vue')
bugsnagVue.installVueErrorHandler(Vue)

Vue.mixin(Mixins)
Vue.use(VueAxios, axios)

Vue.use(VueAuth, {
  auth: VueAuthDevise,
  http: VueAuthHttp,
  router: VueAuthRouter,
  rolesVar: 'role',
  loginData: {
    url: process.env.VUE_APP_SUBSCRIBER_SIGN_IN_URL,
    method: 'POST',
    redirect: { name: 'default' },
    fetchUser: true
  },
  registerData: {
    url: process.env.VUE_APP_SUBSCRIBER_AUTH_URL,
    method: 'POST'
  },
  logoutData: {
    url: process.env.SUBSCRIBER_SIGN_OUT_URL,
    method: 'DELETE',
    redirect: { name: 'login' },
    makeRequest: false
  },
  fetchData: {
    url: process.env.VUE_APP_SUBSCRIBER_VALIDATE_TOKEN_URL,
    method: 'GET',
    enabled: true
  },
  refreshData: {
    url: process.env.VUE_APP_SUBSCRIBER_VALIDATE_TOKEN_URL,
    method: 'GET',
    interval: 30,
    enabled: true
  }
})

Vue.use(VueProgressBar, {
  color: 'var(--blueberry)',
  failedColor: 'var(--light-peach)',
  thickness: '3px'
})

Vue.use(ActionCableVue, {
  debug: process.env.NODE_ENV !== 'production',
  debugLevel: 'all',
  connectionUrl: function () {
    const tokens = Vue.auth.token()?.split('|')
    if (tokens === undefined) {
      return null
    }
    return process.env.VUE_APP_ACTIONCABLE_URL + '?token=' + tokens[1] + '&client=' + tokens[2] + '&uid=' + tokens[3]
  },
  connectImmediately: false
})

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(Notifications, { velocity })
Vue.use(VueTruncate)
Vue.use(VueScrollTo)

Vue.use(VueCurrencyFilter, {
  symbol: '€',
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'back',
  symbolSpacing: false
})

Vue.use(VueLodash, { lodash: lodash })

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GA_CODE }
}, router)

Vue.use(Hotjar, {
  id: process.env.VUE_APP_HOTJAR_ID,
  isProduction: process.env.NODE_ENV === 'production'
})

Vue.use(VueAddtohomescreen)

Vue.component('TimeAgo', TimeAgo)

// eslint-disable-next-line no-undef
Vue.prototype.$wisepops = wisepops

export const app = new Vue({
  router,
  store,
  axios,
  i18n,
  render: h => h(App)
}).$mount('#app')
