export default {
  methods: {
    devise: () => {
      return {
        formatResponse: (response) => {
          let responseMessage = ''
          if (Object.prototype.hasOwnProperty.call(response, 'errors')) {
            if (Object.prototype.hasOwnProperty.call(response.errors, 'full_messages')) {
              response.errors.full_messages.map((value, key) => {
                responseMessage = responseMessage + '<li>' + value + '</li>'
              })
            } else {
              response.errors.map((value, key) => {
                responseMessage = responseMessage + '<li>' + value + '</li>'
              })
            }
            return '<ul>' + responseMessage + '</ul>'
          }
          return false
        }
      }
    }
  },
  computed: {
    biasIconUrl () {
      return (bias_key, { big }) => bias_key == null ? '' : `/img/biases/${bias_key}${big ? '-big' : ''}.svg`
    },
    strip_html () {
      return (text) => text == null ? '' : text.replace(/(<([^>]+)>)/gi, '')
    }
  }
}
