import Vue from 'vue'

const actions = {
  load_updates ({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.axios.get('/updates.json').then((response) => {
        commit('SET_UPDATES', { updates: response.data })

        resolve(response)
      }, error => {
        reject(error)
      })
    })
  },
  load_updates_from_cable ({ commit, state }, { update }) {
    // Current updates from state
    var updates = state.updates

    if (updates.filter(iu => iu.update_id === update.update_id).length > 0) {
      // Update existing intraday_update
      const index = updates.findIndex(iu => iu.update_id === update.update_id)
      Vue.set(updates, index, update)
    } else {
      // Add intraday_update
      updates.unshift(update)
    }
    commit('SET_UPDATES', { updates: updates })
  },
  show_charts_updates ({ commit }) {
    const show_charts_updates = JSON.parse(localStorage.getItem('show_charts_updates'))
    commit('SET_SHOW_CHARTS_UPDATES', show_charts_updates)
  },
  update_show_charts_updates ({ commit }, show_charts_updates) {
    localStorage.setItem('show_charts_updates', JSON.stringify(show_charts_updates))
    commit('SET_SHOW_CHARTS_UPDATES', show_charts_updates)
  }
}

export default actions
