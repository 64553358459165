import Vue from 'vue'

const actions = {
  loadInstruments ({ commit }, { market }) {
    return new Promise((resolve, reject) => {
      Vue.axios.get(`/instruments/${market}.json`).then((response) => {
        commit('SET_INSTRUMENTS', { instruments: response.data })

        resolve(response)
      }, error => {
        reject(error)
      })
    })
  },
  loadFavourites ({ commit }) {
    const data = JSON.parse(localStorage.getItem('favourites'))
    commit('SET_FAVOURITES', { favourites: data })
  },
  updateFavourites ({ commit }, { favourite_id }) {
    var favourites = JSON.parse(localStorage.getItem('favourites')) || []

    if (favourites.includes(favourite_id)) {
      favourites = favourites.filter(favourite => favourite !== favourite_id)
    } else {
      favourites.push(favourite_id)
    }

    localStorage.setItem('favourites', JSON.stringify(favourites))
    commit('SET_FAVOURITES', { favourites: favourites })
  },
  addAllToFavourites ({ commit }, { favourites }) {
    localStorage.setItem('favourites', JSON.stringify(favourites))
    commit('SET_FAVOURITES', { favourites: favourites })
  },
  load_instrument_from_cable ({ commit, state }, { instrument, market }) {
    // Current instruments from state
    var instruments = state.instruments

    if (instruments.filter(i => i.id === instrument.id).length > 0) {
      // Update existing instrument
      const index = instruments.findIndex(i => i.id === instrument.id)
      Vue.set(instruments, index, instrument)
    } else {
      // Add instrument
      if (instrument.markets.find(m => m.slug === market)) {
        instruments.unshift(instrument)
      }
    }
    commit('SET_INSTRUMENTS', { instruments: instruments })
  }
}

export default actions
