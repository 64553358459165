const mutations = {
  OPEN_POPUP (state) {
    state.popup_visibility = true
  },
  CLOSE_POPUP (state) {
    state.popup_visibility = false
  }
}

export default mutations
