<template>
  <img :src="user_theme ? logo_wave_dark_anim : logo_wave_anim" :alt="env.image_alt">
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      env: {
        image_alt: process.env.VUE_APP_NAME
      },
      logo_wave_anim: '/img/logo-wave-anim.gif',
      logo_wave_dark_anim: '/img/logo-wave-dark-anim.gif'
    }
  },
  computed: {
    ...mapState({
      user_theme: state => state.user_theme.user_theme
    })
  }
}
</script>

<style scoped lang="scss">
  img {
    width: 130px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
